.aboutPage{
    .item1{
        margin: 120px 0 70px 0;
        @media (max-width:767px) {
            margin-top: 85px;
            margin-bottom: 55px;
        }
        .it1-bx{
            text-align: center;
            max-width: 820px;
            margin: 0 auto;
            width: 100%;
            .des{
                @include fontset(16,1.5,0,400,#A1A1A1);
                span{
                    display: block;
                    color: #000000;
                    font-weight: 400;
                    @media (max-width:767px) {
                        display: inline;
                        font-weight: 400;
                    }
                }
                margin-bottom: 50px;
                @media (max-width:767px) {
                    font-size: pxToEm(12);
                    margin-bottom: 25px;
                }
            }
        }
    }
    .item2{
        background: url(../images/about/b_img.jpg) no-repeat center center / cover;
        @media (max-width:767px) {
            background: url(../images/about/b_img_mb.jpg) no-repeat center center / cover;
        }
        .container{
            position: relative;
            padding-bottom: 41.6%;
            @media (max-width:767px) {
                padding-bottom: 144%;
            }
        }
        .it2-bx{
           
            position: relative;
            .tx-bx{
                position: absolute;
                right: 0;
                top: 120px;
                max-width: 626px;
                width: 100%;
                @media (max-width:1300px) {
                    width: 45%;
                    top:50px;
                }
                @media (max-width:991px) {
                    width: 53%;
                }
                @media (max-width:767px) {
                    width: 100%;
                    padding: 0 30px;
                }
            }
            .des{
                @include fontset(16,1.5,0,400);
                margin-bottom: 66px;
                @media (max-width:1199px) {
                    margin-bottom: 40px;
                }
                @media (max-width:991px) {
                    font-size: pxToEm(12);
                    margin-bottom: 40px;
                }
            }
            .jp-ti{
                font-family: $Saw;
                @include fontset(28,1,0,400);
                left: -15px;
                @media (max-width:991px) {
                    font-size: pxToEm(18);
                    letter-spacing: 0px;
                }
                @media (max-width:767px) {
                    left: -7px;
                }
            }
            .ceo{
                position: absolute;
                right: 0;
                bottom: 65px;
                @include fontset(16,1.5,0,400,#A1A1A1);
                @media (max-width:991px) {
                    font-size: pxToEm(12);
                }
                @media (max-width:767px) {
                    bottom: -50px;
                    right: 30px;
                }
            }
        }
    }
}
.productPage{
    .item1{
        margin-top: 120px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-top: 67px;
            margin-bottom: 0;
        }
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1054px;
        }
    }
}
.productdetailPage{
    .container{
        @media (min-width:1199px) {
            max-width: 1054px;
        }
    }
    .item1{
        margin-top: 120px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-top: 67px;
            margin-bottom: 0;
        }
    }
    .item2{
        margin-bottom: 120px;
        @media (max-width:767px) {
            margin-bottom: 40px;
        }
        .it2-bx{
            display: flex;
            @media (max-width:767px) {
                display: block;
            }
            .l-bx{
                width: 55%;
                margin-right: 6%;
                @media (max-width:767px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 42px;
                }
                .b-imgbx{
                    box-shadow: 0 0 10.7px -3.6px rgba(0,0,0,.2);
                    @media (max-width:767px) {
                       
                    }
                    ul{
                        position: relative;
                    }
                }
                .s-imgbx{
                    @media (max-width:767px) {
                        margin-top: 5px;
                    }
                    ul{
                        padding: 0 40px;
                        margin-top: 20px;
                        @media (max-width:767px) {
                            padding: 0 30px;
                            margin-top: 10px;
                        }
                        .slick-current{
                            box-shadow: 0 0 10.7px -3.6px rgba(0,0,0,.2);
                            opacity: 1;
                        }
                        .slick-prev{
                            left: 0;
                            transform: rotate(180deg);
                            background: url(../images/icon_arrow_mb.png) no-repeat center center / 8px;
                            @media (max-width:767px) {
                                transform: rotate(180deg) translateY(-50%);
                                top: 30%;
                                background: url(../images/icon_arrow_mb.png) no-repeat center center / 8px;
                            }
                        }
                        .slick-next{
                            right: 0;
                            background: url(../images/icon_arrow_mb.png) no-repeat center center / 8px;
                            @media (max-width:767px) {
                                // transform:none;
                            }
                        }
                        .slick-disabled.slick-prev{
                            background: url(../images/arrow_r_mb.png) no-repeat center center / 7px;
                        }
                        li{
                            margin: 5px;
                            opacity: .5;
                            @media (max-width:767px) {
                                margin: 10px;
                              
                            }
                        }
                    }
                }
            }
            .r-bx{
                width: 39%;
                transition-delay: .4s;
                @media (max-width:767px) {
                    width: 100%;
                    transition-delay: 0s;
                }
                .ti{
                    @include fontset(16,1.5,0,300,#000000);
                    @media (max-width:767px) {
                        font-size: pxToEm(12);
                        margin-bottom: 8px;
                    }
                }
                .proname{
                    @include fontset(28,1,0,700,#DA0E2B);
                    margin-bottom: 32px;
                    padding-bottom: 18px;
                    border-bottom: 1px solid #A1A1A1;
                    @media (max-width:767px) {
                        font-size: pxToEm(22);
                        margin-bottom: 28px;
                        padding-bottom: 11px;
                        border-bottom: 1px solid #e4e4e4;
                    }
                }
                .des-bx{
                    @include fontset(16,1.5,0,400,#A1A1A1);
                    padding-bottom: 40px;
                    border-bottom: 1px solid #A1A1A1;
                    @media (max-width:767px) {
                        font-size: pxToEm(12);
                        line-height: 1.8;
                        padding-bottom: 25px;
                        border-bottom: 1px solid #e4e4e4;
                    }
                }
                .rh-btn{
                    margin-top: 30px;
                    text-align: left;
                    @media (max-width:767px) {
                        margin-top: 20px;
                       
                        .link{
                            padding-right: 35px;
                        }
                        .link::before{
                            background-color: #000000;
                            width: 17px;
                        }
                    }
                    span{
                        font-weight: 400;
                        @media (max-width:767px) {
                            color: #000000;
                        }
                    }
                    @media (min-width:768px) {
                        .link:hover{
                            padding-right: 39px;
                            span{
                                transform: translateX(0px);
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
        .editor_Content{
            margin-top: 40px;
            margin-bottom: 50px;
            @media (max-width:767px) {
                margin-bottom: 20px;
            }
        }
    }
}
.productsearchPage{
    .item1{
        margin-top: 120px;
        margin-bottom: 60px;
        display: none;
        @media (max-width:767px) {
            margin-top: 67px;
            margin-bottom: 0;
        }
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1054px;
        }
    }
    .s-nothing{
        margin-top: 120px;
        margin-bottom: 60px;
        display: none;
        text-align: center;
        padding: 0 20px;
        @media (max-width:767px) {
            margin-top: 67px;
            margin-bottom: 30;
        }
    }
    .showme{
        display: block;
    }
}
.newsPage{
    .item1{
        margin-top: 120px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-top: 67px;
            margin-bottom: 0;
        }
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1054px;
        }
    }
    .news-list{
        margin-bottom: 120px;
        @media (max-width:767px) {
            margin-bottom: 65px;
        }
        li{
            border-bottom: 1px solid #E4E4E4;
            padding: 18px 0 ;
           &:not(:last-child){
               margin-bottom: 0;
           }
           &:first-child{
               padding-top: 0;
           }
        }
    }
}
.newsdetailPage{
    .item1{
        margin-top: 120px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-top: 67px;
            margin-bottom: 65px;
        }
        .date{
            @include fontset(14,2,0,400,#DA0E2B);
            @media (max-width:767px) {
                font-size: pxToEm(12);
            }
        }
        .ti{
            @include fontset(18,1.5,0,400,#242424);
            margin-bottom: 40px;
            @media (max-width:767px) {
                font-size: pxToEm(14);
                margin-bottom: 25px;
            }
        }
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1054px;
        }
    }
    .editor_Content{
        margin-top: 40px;
        margin-bottom: 50px;
        transition-delay: .4s;
        @media (max-width:767px) {
            margin-bottom: 40px;
            margin-top: 25px;
        }
    }
}
.downloadPage{
    .item1{
        margin-top: 120px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-top: 67px;
            margin-bottom: 60px;
        }
        .ti{
            @include fontset(16,1.5,0,400#000000);
            text-align: center;
            @media (max-width:767px) {
                font-size: pxToEm(12);
                margin-bottom: 45px;
            }
        }
        .search-bx{
            margin-bottom: 40px;
            .tx{
                @include fontset(14,2,0,400,#DA0E2B);
                @media (max-width:767px) {
                    font-size: pxToEm(12);
                    line-height: 1.5;
                }
            }
            .input-bx{
                width: 100%;
                position: relative;
                input{
                    width: 100%;
                    height: 46px;
                    border-bottom: 1px solid #A1A1A1;
                    border-radius: 0;
                    &:focus{
                        border: 1px solid rgba(255,255,255,0);
                        border-bottom: 1px solid #A1A1A1;
                    }
                    @media (max-width:767px) {
                        height: 38px;
                    }
                   
                }
                // input placeholder
                ::-webkit-input-placeholder {
                    /* WebKit browsers */
                    color: #666;
                    line-height: 18px;
                    font-family: $font_content;
                }

                :-moz-placeholder {
                    /* Mozilla Firefox 4 to 18 */
                    color: #666;
                    line-height: 43px;
                    font-family: $font_content;
                }

                ::-moz-placeholder {
                    /* Mozilla Firefox 19+ */
                    color: #666;
                    line-height: 43px;
                    font-family: $font_content;
                }

                :-ms-input-placeholder {
                    /* Internet Explorer 10+ */
                    color: #666;
                    line-height: 18px;
                    font-family: $font_content;
                }
                @media (max-width:767px) {
                        // input placeholder
                        ::-webkit-input-placeholder {
                            /* WebKit browsers */
                            color: #666;
                            line-height: 18px;
                            font-family: $font_content;
                            font-size: pxToEm(14);
                        }

                        :-moz-placeholder {
                            /* Mozilla Firefox 4 to 18 */
                            color: #666;
                            line-height: 43px;
                            font-family: $font_content;
                            font-size: pxToEm(14);
                        }

                        ::-moz-placeholder {
                            /* Mozilla Firefox 19+ */
                            color: #666;
                            line-height: 43px;
                            font-family: $font_content;
                            font-size: pxToEm(14);
                        }

                        :-ms-input-placeholder {
                            /* Internet Explorer 10+ */
                            color: #666;
                            line-height: 18px;
                            font-family: $font_content;
                            font-size: pxToEm(14);
                        }
                }
                .send{
                    width: 40px;
                    height: 40px;
                    top: 50%;
                    right: -10px;
                    transform: translateY(-50%);
                    position: absolute;
                    @media (max-width:767px) {
                        right: -10px;
                    }
                    &::before{
                        position: absolute;
                        content: '';
                        width: 18px;
                        height: 18px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        background: url(../images/download/icon_search.jpg) no-repeat center center / 17px;
                    }
                }
            }
        }
        #top-menu-ul2{
            @media (max-width:767px) {
                margin-bottom: 54px;
            }
            .item_menu_Box li.active a{
                div::before{
                    opacity: 1;
                }
            }
            .item_menu_Box li:first-child a {
                margin-left: 0;
            }
            .item_menu_Box li a{
                padding-right: 0;
                margin: 0 20px;
                font-weight: 700;
                div{
                    // border-bottom: 1px solid rgba(255,255,255,0);
                    transition: .4s;
                    position: relative;
                    &::before{
                        position: absolute;
                        content: '';
                        bottom: -5px;
                        width: 100%;
                        height: 1px;
                        background-color: #da0e2b;
                        left: 0;
                        opacity: 0;
                        transition: .6s;
                    }
                }
                @media (min-width:768px) {
                    &:hover{
                        div::before{
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .news-list{
            ul li{
                margin-bottom: 0;
                &:last-child a{
                    @media (min-width:768px) {
                        border-bottom: none;
                    }
                }
            }
            ul li a{
                display: block;
                padding: 20px 0 ;
                border-bottom: 1px solid #E4E4E4;
                @media (max-width:767px) {
                    padding: 15px 0;
                }
            }
        }
        .page{
            margin-top: 110px;
            @media (max-width:768px) {
                margin-top: 50px;
                margin-bottom: 0;
            }
        }

        .data-bx{
            display: none;
        }
        .s-nothing{
            display: none;
            @media (max-width:767px) {
                font-size: pxToEm(14);
            }
        }
        .showme{
            display: block;
        }
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1054px;
        }
    }
}
.globalPage{
    .item1{
        margin-top: 120px;
        margin-bottom: 60px;
        @media (max-width:767px) {
            margin-top: 67px;
            margin-bottom: 15px;
        }
        
        ul{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: start;
            
            li{
                width: calc((100% - 6%) / 3);
                margin-bottom: 48px;
                @media (min-width:991px){
                    &:not(:nth-child(3n)){
                        margin-right: 3%;
                    }
                }
                @media (max-width:991px) {
                    width: 48%;
                    &:nth-child(odd){
                        margin-right: 4%;
                    }
                }
                a{
                    display: flex;
                    align-items: center;
                    @media (min-width:768px) {
                        &:hover{
                            .img-bx{
                                &::before{
                                    opacity: 1;
                                }
                            }
                            .img-bx .more-bx{
                                opacity: 1;
                            }
                        }
                    }
                    .img-bx{
                        border-radius: 50%;
                        position: relative;
                        box-shadow: 0px 0px 8px #0000001F;
                        min-width: 65px;
                        @media (max-width:767px) {
                            min-width: 53px;
                            max-width: 53px;
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0,0,0,.5);
                            border-radius: 50%;
                            transition: .4s;
                            opacity: 0;
                        }
                        .more-bx{
                            position: absolute;
                            width: 100%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            @include fontset(14,1.3,0,700,#FFFFFF);
                            opacity: 0;
                            transition: .4s;
                            text-align: center;
                            
                        }
                    }
                    .name{
                        @include fontset(18,1.5,0,700,#000000);
                        transition: .4s;
                        margin-left: 27px;
                        max-height: 54px;
                        overflow: hidden;
                        @media (max-width:991px) {
                            margin-left: 13px;
                        }
                        @media (max-width:767px) {
                            font-size: pxToEm(14);
                            max-height: 44px;
                        }
                    }
                }
            } 
        }
    }
    .container{
        @media (min-width:1199px) {
            max-width: 1054px;
        }
    }
}
.globaldetail{
    .banner-bx{
        position: relative;
        .banerback-btn{
            width: 190px;
            height: 45px;
            background-color: #DA0E2B;
            position: absolute;
            left: 160px;
            bottom: 45px;
            @media (max-width:1199px) {
                left: 80px;
            }
            @media (max-width:767px) {
                left: 20px;
                bottom: 80px;
                width: 150px;
                height: 30px;
            }
            a{
                @include fontset(16,2.9,0,400,#ffffff);
                display: block;
                height: 100%;
                position: relative;
                padding-left: 67px;
                @media (max-width:767px) {
                    font-size: pxToEm(12);
                    padding-left: 47px;
                    line-height: 2.5;
                }
                span{
                    padding-left: 25px;
                    position: relative;
                    transition: .4s;
                }
                span::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 1px;
                    background-color: #ffffff;
                    transition: .4s;
                }
                @media (min-width:768px) {
                    &:hover{
                        span{
                            padding-left: 35px;
                            &::before{
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
        .banerback-btn2{
            width: 190px;
            height: 45px;
            background-color: #DA0E2B;
            position: absolute;
            left: 400px;
            bottom: 45px;
            @media (max-width:1199px) {
                left: 320px;
            }
            @media (max-width:767px) {
                left: 20px;
                bottom: 140px;
                width: 150px;
                height: 30px;
            }
            a{
                @include fontset(16,2.9,0,400,#ffffff);
                display: block;
                height: 100%;
                position: relative;
                text-align: right;
                padding-right: 27px;
                @media (max-width:767px) {
                    font-size: pxToEm(12);
                    line-height: 2.5;
                    padding-right: 18px;
                }
                span{
                    padding-right: 25px;
                    position: relative;
                    transition: .4s;
                }
                span::before{
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 1px;
                    background-color: #ffffff;
                    transition: .4s;
                }
                @media (min-width:768px) {
                    &:hover{
                        span{
                            padding-right: 35px;
                            &::before{
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}