footer{
    z-index : -10;
   
    padding: 55px 270px 80px 160px;
    @media (max-width:1400px) {
        padding: 55px 95px 80px 55px;
    }
    @media (max-width:1199px) {
        padding: 55px 80px 80px 50px;
    }
    @media (max-width:991px) {
        padding: 55px 20px 80px 20px;
    }
    @media (max-width:767px) {
        padding: 30px 20px 30px 20px;
    }
    .f-logo{
        width: 113px;
        height: 37px;
        margin-bottom: 12px;
        background: url(../images/footer_logo.png) no-repeat center center / cover;
        @media (max-width:767px) {
            margin-bottom: 28px;
            width: 82px;
            height: 27px;
        }
    }
    .f-oubx{
        display: flex;
        justify-content: space-between;
        @media (max-width:767px) {
            flex-wrap: wrap;
            position: relative;
        }
    }
    .l-bx{
        position: relative;
        @include fontset(14,1.7,0,700,#242424);
        padding-right: 3%;
        @media (max-width:767px) {
            position: absolute;
            bottom: 0;
            height: 35px;
            font-size: 11px;
        }
        .copyright{
            @include fontset(13,1.7,0,300,#A1A1A1);
            position: absolute;
            bottom: 0;
            left: 0;
            @media (max-width:767px) {
                font-size: 11px;
            }
        }
    }
    .r-bx{
        display: flex;
        .f-ti{
            @include fontset(14,1.7,0,700,#242424);
            margin-bottom: 12px;
            @media (max-width:767px) {
                font-size: 11px;
                margin-bottom: 3px;
            }
        }
        @media (max-width:767px) {
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    .con-bx{
        margin-right: 1.8%;
        max-width: 173px;
        width: 100%;
        @media (max-width:767px) {
            max-width: 48.5%;
            margin-right: 0;
        }
        ul li{
            padding-left: 21px;
            position: relative;
            @media (max-width:767px) {
                padding-left: 18px;
            }
            span{
                position: absolute;
                top: 0;
                left: 0;
                @include fontset(13,1.7,0,700,#A1A1A1);
                @media (max-width:767px) {
                    font-size: 10px;
                }
            }
            a{
                @include fontset(13,1.7,0,300,#A1A1A1);
                display: block;
                @media (max-width:767px) {
                    font-size: 10px;
                }
            }
        }
    }
    .adr-bx{
        max-width: 296px;
        width: 100%;
        margin-right: 16px;
        .address{
            @include fontset(13,1.7,0,300,#A1A1A1);
            display: block;
            @media (max-width:767px) {
                font-size: 10px;
            }
        }
        @media (max-width:767px) {
            max-width: 48.5%;
            margin-right: 0;
        }
    }
    .flow-bx{
        max-width: 150px;
        width: 100%;
        @media (max-width:767px) {
            margin-top: 30px;
            margin-bottom: 59px;
            max-width: 100%;
            .f-ti{
                display: none;
            }
        }
        .in-bx{
            display: flex;
            flex-wrap: wrap;
            a{
                display: block;
                width: 40px;
                height: 40px;
                position: relative;
                margin-right: 8px;
                margin-bottom: 8px;
            }
            img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 28px;
                height: 28px;
                transition: .4s;
                opacity: 0;
                @media (max-width:767px) {
                    width: 36px;
                    height: 36px;
                }
            }
            .unhov{
                opacity: 1;
            }
            @media (min-width:991px) {
                a:hover{
                    .hov{
                        opacity: 1;
                    }
                    .unhov{
                        opacity: 0;
                    }  
                }
            }
        }
    }
}

