@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
/* > reset */
/******************************************/
/*		slick
/******************************************/
/* Slider */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&family=Sawarabi+Mincho&display=swap");
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../assets/slick.eot");
  src: url("../assets/slick.eot?#iefix") format("embedded-opentype"), url("../assets/slick.woff") format("woff"), url("../assets/slick.ttf") format("truetype"), url("../assets/slick.svg#slick") format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/******************************************/
/*		fullpage
/******************************************/
@CHARSET "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, textarea, p, blockquote {
  padding: 0;
  margin: 0; }

a {
  text-decoration: none; }

table {
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

strong {
  font-weight: bold; }

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
  color: #444; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/* Custom CSS
 * --------------------------------------- */
body {
  font-family: arial,helvetica;
  color: #333;
  color: rgba(0, 0, 0, 0.5); }

.wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative; }

h1 {
  font-size: 6em; }

p {
  font-size: 2em; }

.intro p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.5em; }

.section {
  text-align: center; }

#menu li {
  display: inline-block;
  margin: 10px;
  color: #000;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px; }

#menu li.active {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

#menu li a {
  text-decoration: none;
  color: #000; }

#menu li.active a:hover {
  color: #000; }

#menu li:hover {
  background: rgba(255, 255, 255, 0.8); }

#menu li a,
#menu li.active a {
  padding: 9px 18px;
  display: block; }

#menu li.active a {
  color: #fff; }

#menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0; }

.twitter-share-button {
  position: fixed;
  z-index: 99;
  right: 149px;
  top: 9px; }

#download {
  margin: 10px 0 0 0;
  padding: 15px 10px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #49afcd;
  background-image: -moz-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -ms-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#5bc0de), to(#2f96b4));
  background-image: -webkit-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: -o-linear-gradient(top, #5bc0de, #2f96b4);
  background-image: linear-gradient(top, #5bc0de, #2f96b4);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5bc0de', endColorstr='#2f96b4', GradientType=0);
  border-color: #2f96b4 #2f96b4 #1f6377;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

#download a {
  text-decoration: none;
  color: #fff; }

#download:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2F96B4;
  background-position: 0 -15px;
  -webkit-transition: background-position .1s linear;
  -moz-transition: background-position .1s linear;
  -ms-transition: background-position .1s linear;
  -o-transition: background-position .1s linear;
  transition: background-position .1s linear; }

#infoMenu {
  height: 20px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
  padding: 8px 0 8px 0; }

#infoMenu ul {
  padding: 0 40px; }

#infoMenu li a {
  display: block;
  margin: 0 22px 0 0;
  color: #333; }

#infoMenu li a:hover {
  text-decoration: underline; }

#infoMenu li {
  display: inline-block;
  position: relative; }

#examplesList {
  display: none;
  background: #282828;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 20px;
  float: left;
  position: absolute;
  bottom: 29px;
  right: 0;
  width: 638px;
  text-align: left; }

#examplesList ul {
  padding: 0; }

#examplesList ul li {
  display: block;
  margin: 5px 0; }

#examplesList ul li a {
  color: #BDBDBD;
  margin: 0; }

#examplesList ul li a:hover {
  color: #f2f2f2; }

#examplesList .column {
  float: left;
  margin: 0 20px 0 0; }

#examplesList h3 {
  color: #f2f2f2;
  font-size: 1.2em;
  margin: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  padding: 0 0 5px 0; }

/* Demos Menu
 * --------------------------------------- */
#demosMenu {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999; }

/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #da0e2b; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  z-index: 0;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  opacity: 0;
  transition: 1.2s;
  font-family: "Montserrat", sans-serif; }

body.loading {
  opacity: 1;
  transition: opacity 1.2s; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #666;
  line-height: 18px;
  font-family: "Montserrat", sans-serif; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #666;
  line-height: 43px;
  font-family: "Montserrat", sans-serif; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #666;
  line-height: 43px;
  font-family: "Montserrat", sans-serif; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #666;
  line-height: 18px;
  font-family: "Montserrat", sans-serif; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Montserrat", sans-serif;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow: hidden; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  z-index: 1; }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 0;
  line-height: 1.5;
  letter-spacing: 0;
  font-weight: 400; }
  .editor_Content header {
    width: 100%;
    padding-bottom: 31px;
    margin-bottom: 29px;
    border-bottom: solid 1px #7d7d7d;
    display: flex; }
    .editor_Content header h2 {
      width: 80%;
      font-size: 1.25rem;
      line-height: 1.4;
      color: #ffffff;
      padding-right: 2%; }
    .editor_Content header .tag_list {
      text-align: right;
      width: -moz-calc(100% - 73%);
      width: -webkit-calc(100% - 73%);
      width: calc(100% - 73%); }
  .editor_Content ul, .editor_Content ol {
    padding-left: 40px; }
  .editor_Content ol {
    list-style: decimal; }
  .editor_Content ul {
    list-style: disc; }
  .editor_Content img {
    max-width: 100%;
    height: auto !important; }
  .editor_Content b, .editor_Content strong {
    font-weight: bold; }
  .editor_Content strong {
    font-size: inherit; }
  .editor_Content em {
    font-style: italic; }
  .editor_Content a {
    text-decoration: underline;
    color: #0782C1; }
  .editor_Content iframe {
    max-width: 100%; }
  .editor_Content h1, .editor_Content h2, .editor_Content h3, .editor_Content h4, .editor_Content h5, .editor_Content h6 {
    margin: inherit; }
  .editor_Content blockquote {
    font-style: italic;
    padding: 2px 20px 0 8px;
    margin-left: 40px;
    border-left: 5px solid #ccc; }
  .editor_Content td, .editor_Content th, .editor_Content tr {
    margin: initial;
    padding: initial; }
  .editor_Content table {
    border-collapse: inherit; }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.9375rem;
  color: #000000; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: auto !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .seesaw a {
    color: #de4242;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #de4242;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #de4242;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #de4242; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.topbtn-bx {
  display: none;
  position: relative;
  z-index: 100; }

.top_btn {
  cursor: pointer;
  opacity: 1;
  position: fixed;
  bottom: 40px;
  right: 166px;
  z-index: 9;
  height: 164px;
  width: 28px;
  display: block;
  transition: none; }
  @media (max-width: 1400px) {
    .top_btn {
      right: 55px; } }
  @media (max-width: 991px) {
    .top_btn {
      right: 20px; } }
  .top_btn::before {
    position: absolute;
    content: '';
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 49.6%;
    background-color: #A1A1A1;
    transition: .4s; }
  .top_btn::after {
    position: absolute;
    content: '';
    top: 0;
    width: 28px;
    height: 28px;
    border: 1px solid #A1A1A1;
    border-radius: 50%;
    transition: .4s; }
  .top_btn span {
    font-size: 0.8125rem;
    writing-mode: vertical-rl;
    color: #A1A1A1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    transition: .4s; }
  @media (min-width: 991px) {
    .top_btn:hover::before {
      background-color: #DA0E2B;
      height: 20%; }
    .top_btn:hover::after {
      border-color: #DA0E2B; }
    .top_btn:hover span {
      color: #DA0E2B;
      padding-top: 50px; } }
  .top_btn.fix {
    position: absolute;
    transition: 0s;
    bottom: 80px; }
    @media screen and (max-width: 768px) {
      .top_btn.fix {
        bottom: 30px;
        position: fixed; } }
  @media (max-width: 767px) {
    .top_btn {
      width: 14px;
      height: 79px;
      bottom: 30px; }
      .top_btn::before {
        height: 27%;
        top: 7px; }
      .top_btn::after {
        width: 14px;
        height: 14px; }
      .top_btn span {
        font-size: 11px;
        letter-spacing: 0; } }

/*----------------------頁數------------------------*/
.page {
  font-size: 0.875rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .page {
      margin-bottom: 85px; } }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block;
    width: 7%; }
    .page dt a,
    .page dd a {
      display: block;
      height: 35px;
      line-height: 30px;
      text-decoration: none;
      color: #A1A1A1;
      border-bottom: 2px solid rgba(255, 255, 255, 0);
      border-radius: 0; }
      @media (min-width: 767px) {
        .page dt a:hover,
        .page dd a:hover {
          color: #DA0E2B;
          border-color: #DA0E2B; } }
  .page dd {
    width: 5.3%; }
    @media (max-width: 767px) {
      .page dd {
        width: 15%; } }
  .page dd.active a {
    background: none;
    padding: 0;
    color: #DA0E2B;
    border-color: #DA0E2B; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: 32px;
    height: 32px;
    transition: 0.4s;
    position: relative;
    top: -1px;
    background: url(../images/arrow_r_mb.png) no-repeat center center/8px; }
    @media (max-width: 767px) {
      .page dt.rtbn a,
      .page dt.ltbn a {
        top: -1px;
        background: url(../images/icon_arrow_mb.png) no-repeat center center/9px; } }
  .page dt.nopage.ltbn a, .page dt.nopage.rtbn a {
    pointer-events: none; }
    @media (min-width: 767px) {
      .page dt.nopage.ltbn a:hover, .page dt.nopage.rtbn a:hover {
        background: url(../images/arrow_r_mb.png) no-repeat center center/8px; } }
    @media (max-width: 767px) {
      .page dt.nopage.ltbn a, .page dt.nopage.rtbn a {
        background: url(../images/arrow_r_mb.png) no-repeat center center/8px; } }
  .page dt.ltbn a {
    margin-right: 17px;
    transform: rotate(180deg); }
    @media (min-width: 767px) {
      .page dt.ltbn a:hover {
        background: url(../images/icon_arrow_mb.png) no-repeat center center/9px;
        border-color: rgba(255, 255, 255, 0); } }
    @media (max-width: 767px) {
      .page dt.ltbn a {
        left: -10px; } }
  .page dt.rtbn a {
    margin-left: 17px; }
    @media (min-width: 767px) {
      .page dt.rtbn a:hover {
        background: url(../images/icon_arrow_mb.png) no-repeat center center/9px;
        border-color: rgba(255, 255, 255, 0); } }
    @media (max-width: 767px) {
      .page dt.rtbn a {
        margin-left: 0;
        right: -7px; } }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 20px; }
  right: 20px; }

@media (max-width: 767px) {
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

/*----------------------header------------------------*/
.header_show {
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  z-index: 10; }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.header_box {
  position: relative;
  width: 100%;
  height: 100px;
  padding: 0 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  transition: .4s; }
  @media (max-width: 1199px) {
    .header_box {
      padding: 0 65px; } }
  @media (max-width: 1400px) {
    .header_box {
      padding: 0 55px; } }

.logo {
  width: 184px;
  height: 60px;
  background: url(../images/header_logo.png) center center no-repeat;
  background-size: contain;
  text-indent: -10000px;
  transition: .4s ease; }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }

/*----------------------nav------------------------*/
.nav_box {
  position: relative; }
  .nav_box ul {
    display: flex; }
    .nav_box ul li {
      position: relative;
      text-align: center;
      width: auto;
      transition: .4s;
      transition-delay: .4s;
      pointer-events: auto; }
    .nav_box ul li:not(:first-child) {
      margin-right: 56px; }
      .nav_box ul li:not(:first-child) a::before {
        width: 100%; }
  .nav_box ul.opense li:not(:first-child) {
    opacity: 0;
    transition-delay: 0s;
    pointer-events: none; }
  .nav_box a.active {
    color: #DA0E2B; }
    @media (min-width: 992px) {
      .nav_box a.active::before {
        opacity: 1; } }
    @media (max-width: 991px) {
      .nav_box a.active span {
        position: relative; }
        .nav_box a.active span::before {
          position: absolute;
          content: '';
          bottom: 1px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #DA0E2B; } }
  .nav_box a {
    width: 100%;
    display: block;
    color: rgba(255, 255, 255, 0.5);
    transition: .4s;
    font-size: 0.875rem; }
    @media (max-width: 991px) {
      .nav_box a {
        font-size: 0.75rem;
        font-weight: 700; } }
    .nav_box a::before {
      position: absolute;
      content: '';
      bottom: 0px;
      height: 1px;
      background-color: #DA0E2B;
      transition: .4s;
      opacity: 0; }
    .nav_box a:hover {
      text-decoration: none; }
    @media (min-width: 768px) {
      .nav_box a:hover {
        color: #DA0E2B; }
        .nav_box a:hover::before {
          opacity: 1; } }

.sear-bx {
  order: 6;
  position: relative; }
  @media (max-width: 991px) {
    .sear-bx {
      padding: 21px 0; } }
  .sear-bx .opse {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 8; }
    @media (max-width: 991px) {
      .sear-bx .opse {
        display: none; } }
    .sear-bx .opse::before {
      position: absolute;
      content: '';
      width: 24px;
      height: 24px;
      background: url(../images/icon_search.png) no-repeat center center/cover;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      transition: opacity .4s .4s; }
    .sear-bx .opse::after {
      position: absolute;
      content: '';
      width: 18px;
      height: 18px;
      background: url(../images/header_cancel.png) no-repeat center center/cover;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: .4s; }
  .sear-bx .moopse {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 8;
    padding: 0; }
    @media (min-width: 992px) {
      .sear-bx .moopse {
        display: none; } }
    .sear-bx .moopse::before {
      position: absolute;
      content: '';
      width: 24px;
      height: 24px;
      background: url(../images/icon_search2.png) no-repeat center center/cover;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      transition: opacity .4s .4s; }
  .sear-bx input {
    height: 28px;
    position: absolute;
    right: -9px;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0);
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ffffff;
    width: 0px;
    transition: .4s;
    z-index: 5;
    color: #ffffff; }
    @media (max-width: 991px) {
      .sear-bx input {
        border-bottom: 1px solid #A1A1A1;
        width: 100%;
        right: 0;
        padding-right: 15%;
        color: #a1a1a1; } }
  @media (max-width: 991px) {
    .sear-bx ::-webkit-input-placeholder {
      /* WebKit browsers */
      color: rgba(161, 161, 161, 0.5);
      line-height: 18px;
      font-family: "Montserrat", sans-serif;
      font-size: 0.75rem; }
    .sear-bx :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(161, 161, 161, 0.5);
      line-height: 43px;
      font-family: "Montserrat", sans-serif;
      font-size: 0.75rem; }
    .sear-bx ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(161, 161, 161, 0.5);
      line-height: 43px;
      font-family: "Montserrat", sans-serif;
      font-size: 0.75rem; }
    .sear-bx :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: rgba(161, 161, 161, 0.5);
      line-height: 18px;
      font-family: "Montserrat", sans-serif;
      font-size: 0.75rem; } }

.sear-bx.clse .opse::before {
  opacity: 0;
  transition-delay: 0s; }

.sear-bx.clse .opse::after {
  opacity: 1;
  transition-delay: .4s; }

.sear-bx.clse input {
  width: 360px;
  transition-delay: .4s; }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 10; }

.hamburger-menu:after,
.hamburger-menu:before {
  width: 24px;
  height: 2px;
  -webkit-border-radius: 99em;
  -moz-border-radius: 99em;
  border-radius: 99em; }

.hamburger-menu span {
  width: 24px;
  height: 2px;
  position: absolute;
  right: 8px;
  top: 6.5px;
  background: rgba(255, 255, 255, 0.5); }

.hamburger-menu {
  position: relative;
  transform: translateY(10px);
  background: rgba(255, 255, 255, 0.5);
  transition: all 0ms 300ms;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0); }

.hamburger-menu:before {
  content: "";
  position: absolute;
  right: 8px;
  bottom: 0px;
  background: rgba(255, 255, 255, 0.5);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 15px;
  background: rgba(255, 255, 255, 0.5);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate:after {
  top: 8px;
  transform: rotate(-45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  background-color: #A1A1A1;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px;
  width: 25px; }

.hamburger-menu.animate:before {
  bottom: -10px;
  transform: rotate(45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  background-color: #A1A1A1;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px; }

.hamburger-menu.animate span {
  opacity: 0; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 991.98px) {
  .logo {
    width: 86px;
    height: 49px; }
  .header_box.open {
    background-color: #ffffff; }
    .header_box.open .logo {
      background: url(../images/header_logo.png) no-repeat center center/contain; }
  .header_box {
    width: 100%;
    height: 54px;
    font-size: 1.0625rem;
    margin: 0 auto;
    padding: 0 20px;
    border-bottom: none; }
  .nav_box nav {
    width: 100%;
    margin: auto;
    padding: 0 30px; }
  .nav_box {
    display: none;
    position: fixed;
    top: 53px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #ffffff; }
    .nav_box ul {
      display: block;
      margin-top: 20px; }
      .nav_box ul li {
        width: 100%; }
        .nav_box ul li a {
          padding: 21px 0px;
          text-align: left;
          text-decoration: none;
          color: #A1A1A1;
          letter-spacing: 0; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .menu-wrapper {
    display: block; } }

footer {
  z-index: -10;
  padding: 55px 270px 80px 160px; }
  @media (max-width: 1400px) {
    footer {
      padding: 55px 95px 80px 55px; } }
  @media (max-width: 1199px) {
    footer {
      padding: 55px 80px 80px 50px; } }
  @media (max-width: 991px) {
    footer {
      padding: 55px 20px 80px 20px; } }
  @media (max-width: 767px) {
    footer {
      padding: 30px 20px 30px 20px; } }
  footer .f-logo {
    width: 113px;
    height: 37px;
    margin-bottom: 12px;
    background: url(../images/footer_logo.png) no-repeat center center/cover; }
    @media (max-width: 767px) {
      footer .f-logo {
        margin-bottom: 28px;
        width: 82px;
        height: 27px; } }
  footer .f-oubx {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767px) {
      footer .f-oubx {
        flex-wrap: wrap;
        position: relative; } }
  footer .l-bx {
    position: relative;
    font-size: 0.875rem;
    line-height: 1.7;
    letter-spacing: 0px;
    font-weight: 700;
    color: #242424;
    padding-right: 3%; }
    @media (max-width: 767px) {
      footer .l-bx {
        position: absolute;
        bottom: 0;
        height: 35px;
        font-size: 11px; } }
    footer .l-bx .copyright {
      font-size: 0.8125rem;
      line-height: 1.7;
      letter-spacing: 0px;
      font-weight: 300;
      color: #A1A1A1;
      position: absolute;
      bottom: 0;
      left: 0; }
      @media (max-width: 767px) {
        footer .l-bx .copyright {
          font-size: 11px; } }
  footer .r-bx {
    display: flex; }
    footer .r-bx .f-ti {
      font-size: 0.875rem;
      line-height: 1.7;
      letter-spacing: 0px;
      font-weight: 700;
      color: #242424;
      margin-bottom: 12px; }
      @media (max-width: 767px) {
        footer .r-bx .f-ti {
          font-size: 11px;
          margin-bottom: 3px; } }
    @media (max-width: 767px) {
      footer .r-bx {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between; } }
  footer .con-bx {
    margin-right: 1.8%;
    max-width: 173px;
    width: 100%; }
    @media (max-width: 767px) {
      footer .con-bx {
        max-width: 48.5%;
        margin-right: 0; } }
    footer .con-bx ul li {
      padding-left: 21px;
      position: relative; }
      @media (max-width: 767px) {
        footer .con-bx ul li {
          padding-left: 18px; } }
      footer .con-bx ul li span {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.8125rem;
        line-height: 1.7;
        letter-spacing: 0px;
        font-weight: 700;
        color: #A1A1A1; }
        @media (max-width: 767px) {
          footer .con-bx ul li span {
            font-size: 10px; } }
      footer .con-bx ul li a {
        font-size: 0.8125rem;
        line-height: 1.7;
        letter-spacing: 0px;
        font-weight: 300;
        color: #A1A1A1;
        display: block; }
        @media (max-width: 767px) {
          footer .con-bx ul li a {
            font-size: 10px; } }
  footer .adr-bx {
    max-width: 296px;
    width: 100%;
    margin-right: 16px; }
    footer .adr-bx .address {
      font-size: 0.8125rem;
      line-height: 1.7;
      letter-spacing: 0px;
      font-weight: 300;
      color: #A1A1A1;
      display: block; }
      @media (max-width: 767px) {
        footer .adr-bx .address {
          font-size: 10px; } }
    @media (max-width: 767px) {
      footer .adr-bx {
        max-width: 48.5%;
        margin-right: 0; } }
  footer .flow-bx {
    max-width: 150px;
    width: 100%; }
    @media (max-width: 767px) {
      footer .flow-bx {
        margin-top: 30px;
        margin-bottom: 59px;
        max-width: 100%; }
        footer .flow-bx .f-ti {
          display: none; } }
    footer .flow-bx .in-bx {
      display: flex;
      flex-wrap: wrap; }
      footer .flow-bx .in-bx a {
        display: block;
        width: 40px;
        height: 40px;
        position: relative;
        margin-right: 8px;
        margin-bottom: 8px; }
      footer .flow-bx .in-bx img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28px;
        height: 28px;
        transition: .4s;
        opacity: 0; }
        @media (max-width: 767px) {
          footer .flow-bx .in-bx img {
            width: 36px;
            height: 36px; } }
      footer .flow-bx .in-bx .unhov {
        opacity: 1; }
      @media (min-width: 991px) {
        footer .flow-bx .in-bx a:hover .hov {
          opacity: 1; }
        footer .flow-bx .in-bx a:hover .unhov {
          opacity: 0; } }

/******************************************/
/*		pages
/******************************************/
.header_show.chheader {
  background: #ffffff; }
  .header_show.chheader .logo {
    width: 149px;
    height: 48px; }
    @media (max-width: 991px) {
      .header_show.chheader .logo {
        width: 86px;
        height: 49px; } }
  @media (min-width: 768px) {
    .header_show.chheader .nav_box a {
      color: rgba(0, 0, 0, 0.5); }
      .header_show.chheader .nav_box a:hover {
        color: #DA0E2B; } }
  .header_show.chheader .nav_box a.active {
    color: #DA0E2B; }
  .header_show.chheader .hamburger-menu:before, .header_show.chheader .hamburger-menu:after, .header_show.chheader .hamburger-menu span {
    background-color: #a1a1a1; }
  .header_show.chheader .sear-bx .opse::before {
    background: url(../images/icon_search2.png) no-repeat center center/cover; }
  .header_show.chheader .sear-bx .opse::after {
    background: url(../images/header_cancel2.png) no-repeat center center/cover; }
  .header_show.chheader .sear-bx input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    color: #a1a1a1; }
    @media (max-width: 991px) {
      .header_show.chheader .sear-bx input {
        border-bottom: 1px solid #a1a1a1; } }

.indexPage .banner-bx {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative; }
  .indexPage .banner-bx .slick-dots {
    margin: -90px -145px 0;
    text-align: right; }
    @media (max-width: 991px) {
      .indexPage .banner-bx .slick-dots {
        margin: -39px -5px 0; } }
  .indexPage .banner-bx .slick-dots li button::before {
    width: 0; }
  .indexPage .banner-bx .slick-dots li button {
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 400;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    opacity: .3;
    transition: .4s;
    width: 65px;
    position: relative;
    padding: 0;
    text-align: left; }
    @media (max-width: 991px) {
      .indexPage .banner-bx .slick-dots li button {
        width: 44px;
        font-size: 0.75rem; } }
  .indexPage .banner-bx .slick-dots li button::before {
    position: absolute;
    left: auto;
    right: 0;
    width: 44px;
    height: 1px;
    top: 50%;
    transform: translateY(-50%);
    transition: .4s;
    background-color: rgba(255, 255, 255, 0.3); }
    @media (max-width: 991px) {
      .indexPage .banner-bx .slick-dots li button::before {
        width: 22px; } }
  .indexPage .banner-bx .slick-dots li:last-child button::before {
    opacity: 0; }
  .indexPage .banner-bx .slick-dots li {
    position: relative;
    width: auto;
    margin: 0;
    margin-right: 5px; }
  .indexPage .banner-bx .slick-dots li.slick-active button {
    opacity: 1; }
  .indexPage .banner-bx .slick-dots li.slick-active button {
    opacity: 1;
    width: 151px; }
    @media (max-width: 991px) {
      .indexPage .banner-bx .slick-dots li.slick-active button {
        width: 82px; } }
    .indexPage .banner-bx .slick-dots li.slick-active button::before {
      width: 132px;
      background-color: #ffffff;
      opacity: 1; }
      @media (max-width: 991px) {
        .indexPage .banner-bx .slick-dots li.slick-active button::before {
          width: 60px; } }
  .indexPage .banner-bx .bgcover {
    background-size: cover !important; }
  .indexPage .banner-bx .pc-banner {
    width: 100vw;
    height: 100vh;
    display: none; }
    @media (min-width: 768px) {
      .indexPage .banner-bx .pc-banner {
        display: block; } }
    .indexPage .banner-bx .pc-banner .bgcover {
      width: 100vw;
      height: 100vh; }
  .indexPage .banner-bx .mo-banner {
    width: 100vw;
    height: 100vh;
    display: none; }
    @media (max-width: 767px) {
      .indexPage .banner-bx .mo-banner {
        display: block; } }
    .indexPage .banner-bx .mo-banner .bgcover {
      width: 100vw;
      height: 100vh; }
  .indexPage .banner-bx .scroll-line {
    position: absolute;
    left: 160px;
    bottom: 75px;
    opacity: 1;
    z-index: 9;
    height: 164px;
    width: 28px;
    display: block;
    transition: none; }
    @media (max-width: 1350px) {
      .indexPage .banner-bx .scroll-line {
        left: 30px; } }
    @media (max-width: 991px) {
      .indexPage .banner-bx .scroll-line {
        bottom: 20px;
        height: 92px;
        left: 14px; } }
    .indexPage .banner-bx .scroll-line::before {
      position: absolute;
      content: '';
      bottom: 14px;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 49.6%;
      background-color: #A1A1A1;
      transition: .4s; }
      @media (max-width: 991px) {
        .indexPage .banner-bx .scroll-line::before {
          height: 22.6%;
          bottom: 7px; } }
    .indexPage .banner-bx .scroll-line::after {
      position: absolute;
      content: '';
      bottom: 0;
      width: 28px;
      height: 28px;
      border: 1px solid #A1A1A1;
      border-radius: 50%;
      transition: .4s;
      animation: cirani 2s 1s linear infinite;
      left: 50%;
      transform: translateX(-50%); }
      @media (max-width: 991px) {
        .indexPage .banner-bx .scroll-line::after {
          width: 14px;
          height: 14px;
          animation: cirmoani 1s 1s linear infinite; } }

@keyframes cirani {
  0% {
    bottom: 0;
    opacity: 1; }
  100% {
    bottom: 70px;
    opacity: 0; } }

@keyframes cirmoani {
  0% {
    bottom: 0;
    opacity: 1; }
  100% {
    bottom: 15px;
    opacity: 0; } }
    .indexPage .banner-bx .scroll-line span {
      font-size: 0.8125rem;
      writing-mode: vertical-rl;
      color: #A1A1A1;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
      transition: .4s; }
      @media (max-width: 991px) {
        .indexPage .banner-bx .scroll-line span {
          font-size: 11px; } }

.indexPage .video-bx {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative; }
  @media (max-width: 767px) {
    .indexPage .video-bx {
      background-color: #000000; } }
  .indexPage .video-bx #player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120% !important;
    height: 120% !important;
    min-width: 1440px;
    pointer-events: none; }
    @media (max-width: 767px) {
      .indexPage .video-bx #player {
        width: 90% !important;
        height: 35% !important;
        min-width: auto; } }
  .indexPage .video-bx .bgcover {
    width: 100vw;
    height: 100vh;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 80; }
  .indexPage .video-bx .star-btn {
    width: 72px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 85;
    background: url(../images/icon_yt.png) no-repeat center center/cover; }
  .indexPage .video-bx .scroll-line {
    position: absolute;
    left: 160px;
    bottom: 75px;
    opacity: 1;
    z-index: 9;
    height: 164px;
    width: 28px;
    display: block;
    transition: none; }
    @media (max-width: 1350px) {
      .indexPage .video-bx .scroll-line {
        left: 30px; } }
    @media (max-width: 991px) {
      .indexPage .video-bx .scroll-line {
        bottom: 20px;
        height: 92px;
        left: 14px; } }
    .indexPage .video-bx .scroll-line::before {
      position: absolute;
      content: '';
      bottom: 14px;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 49.6%;
      background-color: #A1A1A1;
      transition: .4s; }
      @media (max-width: 991px) {
        .indexPage .video-bx .scroll-line::before {
          height: 22.6%;
          bottom: 7px; } }
    .indexPage .video-bx .scroll-line::after {
      position: absolute;
      content: '';
      bottom: 0;
      width: 28px;
      height: 28px;
      border: 1px solid #A1A1A1;
      border-radius: 50%;
      transition: .4s;
      animation: cirani 2s 1s linear infinite;
      left: 50%;
      transform: translateX(-50%); }
      @media (max-width: 991px) {
        .indexPage .video-bx .scroll-line::after {
          width: 14px;
          height: 14px;
          animation: cirmoani 1s 1s linear infinite; } }

@keyframes cirani {
  0% {
    bottom: 0;
    opacity: 1; }
  100% {
    bottom: 70px;
    opacity: 0; } }

@keyframes cirmoani {
  0% {
    bottom: 0;
    opacity: 1; }
  100% {
    bottom: 15px;
    opacity: 0; } }
    .indexPage .video-bx .scroll-line span {
      font-size: 0.8125rem;
      writing-mode: vertical-rl;
      color: #A1A1A1;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
      transition: .4s; }
      @media (max-width: 991px) {
        .indexPage .video-bx .scroll-line span {
          font-size: 11px; } }

.indexPage .item1 {
  margin-top: 120px;
  margin-bottom: 110px; }
  @media (max-width: 991px) {
    .indexPage .item1 {
      margin-top: 60px;
      margin-bottom: 55px; } }
  .indexPage .item1 .it1-bx {
    text-align: center;
    max-width: 820px;
    margin: 0 auto;
    width: 100%; }
    .indexPage .item1 .it1-bx .ti {
      font-size: 1.75rem;
      line-height: 1.5;
      letter-spacing: 0px;
      font-weight: 700;
      color: #000000;
      margin-bottom: 30px; }
      @media (max-width: 767px) {
        .indexPage .item1 .it1-bx .ti {
          font-size: 1.3125rem;
          margin-bottom: 20px; } }
    .indexPage .item1 .it1-bx .des {
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0px;
      font-weight: 400;
      color: #A1A1A1;
      margin-bottom: 50px; }
      .indexPage .item1 .it1-bx .des span {
        display: block;
        color: #000000;
        font-weight: 400; }
        @media (max-width: 767px) {
          .indexPage .item1 .it1-bx .des span {
            display: inline;
            font-weight: 400; } }
      @media (max-width: 767px) {
        .indexPage .item1 .it1-bx .des {
          font-size: 0.75rem;
          margin-bottom: 25px; } }

.indexPage .item2 .it-bx {
  background: url(../images/b_product_bg.jpg) no-repeat center center/cover;
  font-size: 1.75rem;
  line-height: 1;
  letter-spacing: 0px;
  font-weight: 700;
  color: #000000;
  display: block;
  width: 100%;
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    .indexPage .item2 .it-bx {
      padding-bottom: 30px;
      background: url(../images/b_product_bg_mb.jpg) no-repeat center center/cover;
      margin-bottom: 7px; } }
  .indexPage .item2 .it-bx:nth-child(even) .l-bx {
    order: 2; }
  .indexPage .item2 .it-bx:nth-child(even) .r-bx {
    order: 1; }
  @media (min-width: 767px) {
    .indexPage .item2 .it-bx:hover {
      background: url(../images/b_product_bg_h.jpg) no-repeat center center/cover; }
      .indexPage .item2 .it-bx:hover .link span {
        transform: translateX(-15px);
        color: #DA0E2B;
        font-weight: 700; }
      .indexPage .item2 .it-bx:hover .link::before {
        width: 34px;
        background-color: #DA0E2B; } }
  .indexPage .item2 .it-bx .ins-bx {
    display: flex; }
    @media (max-width: 1199px) {
      .indexPage .item2 .it-bx .ins-bx {
        max-width: 100%; } }
    @media (max-width: 767px) {
      .indexPage .item2 .it-bx .ins-bx {
        display: block; } }
  @media (max-width: 767px) {
    .indexPage .item2 .it-bx .l-bx {
      margin-bottom: 10px; } }
  @media (max-width: 991px) {
    .indexPage .item2 .it-bx .l-bx img {
      width: 100%; } }
  .indexPage .item2 .it-bx .r-bx {
    position: relative;
    width: 50%; }
    @media (max-width: 767px) {
      .indexPage .item2 .it-bx .r-bx {
        width: 100%; } }
    .indexPage .item2 .it-bx .r-bx .tx-bx {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%); }
      .indexPage .item2 .it-bx .r-bx .tx-bx .ti {
        font-size: 1.75rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 700;
        color: #000000;
        margin-bottom: 52px; }
        @media (max-width: 767px) {
          .indexPage .item2 .it-bx .r-bx .tx-bx .ti {
            font-size: 1.3125rem;
            margin-bottom: 5px; } }
      @media (max-width: 767px) {
        .indexPage .item2 .it-bx .r-bx .tx-bx {
          position: relative;
          top: auto;
          left: auto;
          transform: none; } }

.indexPage .item3 {
  margin-top: 125px;
  margin-bottom: 65px; }
  @media (max-width: 767px) {
    .indexPage .item3 {
      margin-top: 62px;
      margin-bottom: 70px; } }
  @media (min-width: 1199px) {
    .indexPage .item3 .container {
      max-width: 1054px; } }
  .indexPage .item3 .it3-bx .ti-bx {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 45px; }
    @media (max-width: 767px) {
      .indexPage .item3 .it3-bx .ti-bx {
        margin-bottom: 22px; } }
    .indexPage .item3 .it3-bx .ti-bx .l-ti {
      font-size: 1.75rem;
      line-height: 1.5;
      letter-spacing: 0px;
      font-weight: 700;
      color: #000000; }
      @media (max-width: 767px) {
        .indexPage .item3 .it3-bx .ti-bx .l-ti {
          font-size: 1.3125rem;
          line-height: 1.1; } }
    .indexPage .item3 .it3-bx .ti-bx .r-more a {
      font-size: 0.875rem;
      line-height: 1.2;
      letter-spacing: 0px;
      font-weight: 700;
      color: #000000;
      padding-right: 15px;
      position: relative; }
      @media (max-width: 767px) {
        .indexPage .item3 .it3-bx .ti-bx .r-more a {
          font-size: 0.75rem; } }
      .indexPage .item3 .it3-bx .ti-bx .r-more a::before {
        position: absolute;
        content: '';
        right: 0;
        background: url(../images/icon_arrow_mb.png) no-repeat center/5px;
        width: 5px;
        height: 10px;
        top: 6px; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .ti-bx .r-more a::before {
            top: 3px; } }
      .indexPage .item3 .it3-bx .ti-bx .r-more a::after {
        position: absolute;
        content: '';
        left: 0;
        width: 38px;
        height: 1px;
        background-color: #DA0E2B;
        transition: .4s;
        bottom: 1px; }
        @media (max-width: 767px) {
          .indexPage .item3 .it3-bx .ti-bx .r-more a::after {
            width: 33px; } }
      @media (min-width: 767px) {
        .indexPage .item3 .it3-bx .ti-bx .r-more a:hover {
          color: #DA0E2B; }
          .indexPage .item3 .it3-bx .ti-bx .r-more a:hover::after {
            opacity: 0; } }

.indexPage .item4 {
  background: url(../images/c_img.jpg) no-repeat center center/cover; }
  @media (max-width: 767px) {
    .indexPage .item4 {
      background: url(../images/c_img_mb.jpg) no-repeat center center/cover; } }
  @media (min-width: 1199px) {
    .indexPage .item4 .container {
      max-width: 1054px; } }
  .indexPage .item4 .it4-bx {
    padding-bottom: 25%;
    position: relative; }
    @media (max-width: 767px) {
      .indexPage .item4 .it4-bx {
        padding-bottom: 104%; } }
    .indexPage .item4 .it4-bx .tx-bx {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 50%; }
      @media (max-width: 767px) {
        .indexPage .item4 .it4-bx .tx-bx {
          top: auto;
          bottom: 30px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          text-align: center; } }
      .indexPage .item4 .it4-bx .tx-bx .ti {
        font-size: 1.75rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 700;
        color: #000000;
        margin-bottom: 15px; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx .tx-bx .ti {
            font-size: 1.3125rem;
            margin-bottom: 7px; } }
      .indexPage .item4 .it4-bx .tx-bx .des {
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 50px; }
        @media (max-width: 767px) {
          .indexPage .item4 .it4-bx .tx-bx .des {
            font-size: 0.75rem;
            margin-bottom: 10px; } }
  .indexPage .item4 .r-more a {
    font-size: 0.875rem;
    line-height: 1.2;
    letter-spacing: 0px;
    font-weight: 700;
    color: #000000;
    padding-right: 15px;
    position: relative; }
    @media (max-width: 767px) {
      .indexPage .item4 .r-more a {
        font-size: 0.75rem; } }
    .indexPage .item4 .r-more a::before {
      position: absolute;
      content: '';
      right: 0;
      background: url(../images/icon_arrow_mb.png) no-repeat center/5px;
      width: 5px;
      height: 10px;
      top: 6px; }
      @media (max-width: 767px) {
        .indexPage .item4 .r-more a::before {
          top: 3px; } }
    .indexPage .item4 .r-more a::after {
      position: absolute;
      content: '';
      left: 0;
      width: 47px;
      height: 1px;
      background-color: #DA0E2B;
      transition: .4s;
      bottom: 1px; }
      @media (max-width: 767px) {
        .indexPage .item4 .r-more a::after {
          width: 40px; } }
    @media (min-width: 767px) {
      .indexPage .item4 .r-more a:hover {
        color: #DA0E2B; }
        .indexPage .item4 .r-more a:hover::after {
          opacity: 0; } }

.aboutPage .item1 {
  margin: 120px 0 70px 0; }
  @media (max-width: 767px) {
    .aboutPage .item1 {
      margin-top: 85px;
      margin-bottom: 55px; } }
  .aboutPage .item1 .it1-bx {
    text-align: center;
    max-width: 820px;
    margin: 0 auto;
    width: 100%; }
    .aboutPage .item1 .it1-bx .des {
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0px;
      font-weight: 400;
      color: #A1A1A1;
      margin-bottom: 50px; }
      .aboutPage .item1 .it1-bx .des span {
        display: block;
        color: #000000;
        font-weight: 400; }
        @media (max-width: 767px) {
          .aboutPage .item1 .it1-bx .des span {
            display: inline;
            font-weight: 400; } }
      @media (max-width: 767px) {
        .aboutPage .item1 .it1-bx .des {
          font-size: 0.75rem;
          margin-bottom: 25px; } }

.aboutPage .item2 {
  background: url(../images/about/b_img.jpg) no-repeat center center/cover; }
  @media (max-width: 767px) {
    .aboutPage .item2 {
      background: url(../images/about/b_img_mb.jpg) no-repeat center center/cover; } }
  .aboutPage .item2 .container {
    position: relative;
    padding-bottom: 41.6%; }
    @media (max-width: 767px) {
      .aboutPage .item2 .container {
        padding-bottom: 144%; } }
  .aboutPage .item2 .it2-bx {
    position: relative; }
    .aboutPage .item2 .it2-bx .tx-bx {
      position: absolute;
      right: 0;
      top: 120px;
      max-width: 626px;
      width: 100%; }
      @media (max-width: 1300px) {
        .aboutPage .item2 .it2-bx .tx-bx {
          width: 45%;
          top: 50px; } }
      @media (max-width: 991px) {
        .aboutPage .item2 .it2-bx .tx-bx {
          width: 53%; } }
      @media (max-width: 767px) {
        .aboutPage .item2 .it2-bx .tx-bx {
          width: 100%;
          padding: 0 30px; } }
    .aboutPage .item2 .it2-bx .des {
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 66px; }
      @media (max-width: 1199px) {
        .aboutPage .item2 .it2-bx .des {
          margin-bottom: 40px; } }
      @media (max-width: 991px) {
        .aboutPage .item2 .it2-bx .des {
          font-size: 0.75rem;
          margin-bottom: 40px; } }
    .aboutPage .item2 .it2-bx .jp-ti {
      font-family: "Sawarabi Mincho", sans-serif;
      font-size: 1.75rem;
      line-height: 1;
      letter-spacing: 0px;
      font-weight: 400;
      color: #000000;
      left: -15px; }
      @media (max-width: 991px) {
        .aboutPage .item2 .it2-bx .jp-ti {
          font-size: 1.125rem;
          letter-spacing: 0px; } }
      @media (max-width: 767px) {
        .aboutPage .item2 .it2-bx .jp-ti {
          left: -7px; } }
    .aboutPage .item2 .it2-bx .ceo {
      position: absolute;
      right: 0;
      bottom: 65px;
      font-size: 1rem;
      line-height: 1.5;
      letter-spacing: 0px;
      font-weight: 400;
      color: #A1A1A1; }
      @media (max-width: 991px) {
        .aboutPage .item2 .it2-bx .ceo {
          font-size: 0.75rem; } }
      @media (max-width: 767px) {
        .aboutPage .item2 .it2-bx .ceo {
          bottom: -50px;
          right: 30px; } }

.productPage .item1 {
  margin-top: 120px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .productPage .item1 {
      margin-top: 67px;
      margin-bottom: 0; } }

@media (min-width: 1199px) {
  .productPage .container {
    max-width: 1054px; } }

@media (min-width: 1199px) {
  .productdetailPage .container {
    max-width: 1054px; } }

.productdetailPage .item1 {
  margin-top: 120px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .productdetailPage .item1 {
      margin-top: 67px;
      margin-bottom: 0; } }

.productdetailPage .item2 {
  margin-bottom: 120px; }
  @media (max-width: 767px) {
    .productdetailPage .item2 {
      margin-bottom: 40px; } }
  .productdetailPage .item2 .it2-bx {
    display: flex; }
    @media (max-width: 767px) {
      .productdetailPage .item2 .it2-bx {
        display: block; } }
    .productdetailPage .item2 .it2-bx .l-bx {
      width: 55%;
      margin-right: 6%; }
      @media (max-width: 767px) {
        .productdetailPage .item2 .it2-bx .l-bx {
          width: 100%;
          margin-right: 0;
          margin-bottom: 42px; } }
      .productdetailPage .item2 .it2-bx .l-bx .b-imgbx {
        box-shadow: 0 0 10.7px -3.6px rgba(0, 0, 0, 0.2); }
        .productdetailPage .item2 .it2-bx .l-bx .b-imgbx ul {
          position: relative; }
      @media (max-width: 767px) {
        .productdetailPage .item2 .it2-bx .l-bx .s-imgbx {
          margin-top: 5px; } }
      .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul {
        padding: 0 40px;
        margin-top: 20px; }
        @media (max-width: 767px) {
          .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul {
            padding: 0 30px;
            margin-top: 10px; } }
        .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul .slick-current {
          box-shadow: 0 0 10.7px -3.6px rgba(0, 0, 0, 0.2);
          opacity: 1; }
        .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul .slick-prev {
          left: 0;
          transform: rotate(180deg);
          background: url(../images/icon_arrow_mb.png) no-repeat center center/8px; }
          @media (max-width: 767px) {
            .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul .slick-prev {
              transform: rotate(180deg) translateY(-50%);
              top: 30%;
              background: url(../images/icon_arrow_mb.png) no-repeat center center/8px; } }
        .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul .slick-next {
          right: 0;
          background: url(../images/icon_arrow_mb.png) no-repeat center center/8px; }
        .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul .slick-disabled.slick-prev {
          background: url(../images/arrow_r_mb.png) no-repeat center center/7px; }
        .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul li {
          margin: 5px;
          opacity: .5; }
          @media (max-width: 767px) {
            .productdetailPage .item2 .it2-bx .l-bx .s-imgbx ul li {
              margin: 10px; } }
    .productdetailPage .item2 .it2-bx .r-bx {
      width: 39%;
      transition-delay: .4s; }
      @media (max-width: 767px) {
        .productdetailPage .item2 .it2-bx .r-bx {
          width: 100%;
          transition-delay: 0s; } }
      .productdetailPage .item2 .it2-bx .r-bx .ti {
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0px;
        font-weight: 300;
        color: #000000; }
        @media (max-width: 767px) {
          .productdetailPage .item2 .it2-bx .r-bx .ti {
            font-size: 0.75rem;
            margin-bottom: 8px; } }
      .productdetailPage .item2 .it2-bx .r-bx .proname {
        font-size: 1.75rem;
        line-height: 1;
        letter-spacing: 0px;
        font-weight: 700;
        color: #DA0E2B;
        margin-bottom: 32px;
        padding-bottom: 18px;
        border-bottom: 1px solid #A1A1A1; }
        @media (max-width: 767px) {
          .productdetailPage .item2 .it2-bx .r-bx .proname {
            font-size: 1.375rem;
            margin-bottom: 28px;
            padding-bottom: 11px;
            border-bottom: 1px solid #e4e4e4; } }
      .productdetailPage .item2 .it2-bx .r-bx .des-bx {
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0px;
        font-weight: 400;
        color: #A1A1A1;
        padding-bottom: 40px;
        border-bottom: 1px solid #A1A1A1; }
        @media (max-width: 767px) {
          .productdetailPage .item2 .it2-bx .r-bx .des-bx {
            font-size: 0.75rem;
            line-height: 1.8;
            padding-bottom: 25px;
            border-bottom: 1px solid #e4e4e4; } }
      .productdetailPage .item2 .it2-bx .r-bx .rh-btn {
        margin-top: 30px;
        text-align: left; }
        @media (max-width: 767px) {
          .productdetailPage .item2 .it2-bx .r-bx .rh-btn {
            margin-top: 20px; }
            .productdetailPage .item2 .it2-bx .r-bx .rh-btn .link {
              padding-right: 35px; }
            .productdetailPage .item2 .it2-bx .r-bx .rh-btn .link::before {
              background-color: #000000;
              width: 17px; } }
        .productdetailPage .item2 .it2-bx .r-bx .rh-btn span {
          font-weight: 400; }
          @media (max-width: 767px) {
            .productdetailPage .item2 .it2-bx .r-bx .rh-btn span {
              color: #000000; } }
        @media (min-width: 768px) {
          .productdetailPage .item2 .it2-bx .r-bx .rh-btn .link:hover {
            padding-right: 39px; }
            .productdetailPage .item2 .it2-bx .r-bx .rh-btn .link:hover span {
              transform: translateX(0px);
              font-weight: 400; } }
  .productdetailPage .item2 .editor_Content {
    margin-top: 40px;
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      .productdetailPage .item2 .editor_Content {
        margin-bottom: 20px; } }

.productsearchPage .item1 {
  margin-top: 120px;
  margin-bottom: 60px;
  display: none; }
  @media (max-width: 767px) {
    .productsearchPage .item1 {
      margin-top: 67px;
      margin-bottom: 0; } }

@media (min-width: 1199px) {
  .productsearchPage .container {
    max-width: 1054px; } }

.productsearchPage .s-nothing {
  margin-top: 120px;
  margin-bottom: 60px;
  display: none;
  text-align: center;
  padding: 0 20px; }
  @media (max-width: 767px) {
    .productsearchPage .s-nothing {
      margin-top: 67px;
      margin-bottom: 30; } }

.productsearchPage .showme {
  display: block; }

.newsPage .item1 {
  margin-top: 120px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .newsPage .item1 {
      margin-top: 67px;
      margin-bottom: 0; } }

@media (min-width: 1199px) {
  .newsPage .container {
    max-width: 1054px; } }

.newsPage .news-list {
  margin-bottom: 120px; }
  @media (max-width: 767px) {
    .newsPage .news-list {
      margin-bottom: 65px; } }
  .newsPage .news-list li {
    border-bottom: 1px solid #E4E4E4;
    padding: 18px 0; }
    .newsPage .news-list li:not(:last-child) {
      margin-bottom: 0; }
    .newsPage .news-list li:first-child {
      padding-top: 0; }

.newsdetailPage .item1 {
  margin-top: 120px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .newsdetailPage .item1 {
      margin-top: 67px;
      margin-bottom: 65px; } }
  .newsdetailPage .item1 .date {
    font-size: 0.875rem;
    line-height: 2;
    letter-spacing: 0px;
    font-weight: 400;
    color: #DA0E2B; }
    @media (max-width: 767px) {
      .newsdetailPage .item1 .date {
        font-size: 0.75rem; } }
  .newsdetailPage .item1 .ti {
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 400;
    color: #242424;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .newsdetailPage .item1 .ti {
        font-size: 0.875rem;
        margin-bottom: 25px; } }

@media (min-width: 1199px) {
  .newsdetailPage .container {
    max-width: 1054px; } }

.newsdetailPage .editor_Content {
  margin-top: 40px;
  margin-bottom: 50px;
  transition-delay: .4s; }
  @media (max-width: 767px) {
    .newsdetailPage .editor_Content {
      margin-bottom: 40px;
      margin-top: 25px; } }

.downloadPage .item1 {
  margin-top: 120px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .downloadPage .item1 {
      margin-top: 67px;
      margin-bottom: 60px; } }
  .downloadPage .item1 .ti {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 400 #000000;
    color: #000000;
    text-align: center; }
    @media (max-width: 767px) {
      .downloadPage .item1 .ti {
        font-size: 0.75rem;
        margin-bottom: 45px; } }
  .downloadPage .item1 .search-bx {
    margin-bottom: 40px; }
    .downloadPage .item1 .search-bx .tx {
      font-size: 0.875rem;
      line-height: 2;
      letter-spacing: 0px;
      font-weight: 400;
      color: #DA0E2B; }
      @media (max-width: 767px) {
        .downloadPage .item1 .search-bx .tx {
          font-size: 0.75rem;
          line-height: 1.5; } }
    .downloadPage .item1 .search-bx .input-bx {
      width: 100%;
      position: relative; }
      .downloadPage .item1 .search-bx .input-bx input {
        width: 100%;
        height: 46px;
        border-bottom: 1px solid #A1A1A1;
        border-radius: 0; }
        .downloadPage .item1 .search-bx .input-bx input:focus {
          border: 1px solid rgba(255, 255, 255, 0);
          border-bottom: 1px solid #A1A1A1; }
        @media (max-width: 767px) {
          .downloadPage .item1 .search-bx .input-bx input {
            height: 38px; } }
      .downloadPage .item1 .search-bx .input-bx ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #666;
        line-height: 18px;
        font-family: "Montserrat", sans-serif; }
      .downloadPage .item1 .search-bx .input-bx :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #666;
        line-height: 43px;
        font-family: "Montserrat", sans-serif; }
      .downloadPage .item1 .search-bx .input-bx ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #666;
        line-height: 43px;
        font-family: "Montserrat", sans-serif; }
      .downloadPage .item1 .search-bx .input-bx :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #666;
        line-height: 18px;
        font-family: "Montserrat", sans-serif; }
      @media (max-width: 767px) {
        .downloadPage .item1 .search-bx .input-bx ::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #666;
          line-height: 18px;
          font-family: "Montserrat", sans-serif;
          font-size: 0.875rem; }
        .downloadPage .item1 .search-bx .input-bx :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #666;
          line-height: 43px;
          font-family: "Montserrat", sans-serif;
          font-size: 0.875rem; }
        .downloadPage .item1 .search-bx .input-bx ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #666;
          line-height: 43px;
          font-family: "Montserrat", sans-serif;
          font-size: 0.875rem; }
        .downloadPage .item1 .search-bx .input-bx :-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #666;
          line-height: 18px;
          font-family: "Montserrat", sans-serif;
          font-size: 0.875rem; } }
      .downloadPage .item1 .search-bx .input-bx .send {
        width: 40px;
        height: 40px;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        position: absolute; }
        @media (max-width: 767px) {
          .downloadPage .item1 .search-bx .input-bx .send {
            right: -10px; } }
        .downloadPage .item1 .search-bx .input-bx .send::before {
          position: absolute;
          content: '';
          width: 18px;
          height: 18px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url(../images/download/icon_search.jpg) no-repeat center center/17px; }
  @media (max-width: 767px) {
    .downloadPage .item1 #top-menu-ul2 {
      margin-bottom: 54px; } }
  .downloadPage .item1 #top-menu-ul2 .item_menu_Box li.active a div::before {
    opacity: 1; }
  .downloadPage .item1 #top-menu-ul2 .item_menu_Box li:first-child a {
    margin-left: 0; }
  .downloadPage .item1 #top-menu-ul2 .item_menu_Box li a {
    padding-right: 0;
    margin: 0 20px;
    font-weight: 700; }
    .downloadPage .item1 #top-menu-ul2 .item_menu_Box li a div {
      transition: .4s;
      position: relative; }
      .downloadPage .item1 #top-menu-ul2 .item_menu_Box li a div::before {
        position: absolute;
        content: '';
        bottom: -5px;
        width: 100%;
        height: 1px;
        background-color: #da0e2b;
        left: 0;
        opacity: 0;
        transition: .6s; }
    @media (min-width: 768px) {
      .downloadPage .item1 #top-menu-ul2 .item_menu_Box li a:hover div::before {
        opacity: 1; } }
  .downloadPage .item1 .news-list ul li {
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .downloadPage .item1 .news-list ul li:last-child a {
        border-bottom: none; } }
  .downloadPage .item1 .news-list ul li a {
    display: block;
    padding: 20px 0;
    border-bottom: 1px solid #E4E4E4; }
    @media (max-width: 767px) {
      .downloadPage .item1 .news-list ul li a {
        padding: 15px 0; } }
  .downloadPage .item1 .page {
    margin-top: 110px; }
    @media (max-width: 768px) {
      .downloadPage .item1 .page {
        margin-top: 50px;
        margin-bottom: 0; } }
  .downloadPage .item1 .data-bx {
    display: none; }
  .downloadPage .item1 .s-nothing {
    display: none; }
    @media (max-width: 767px) {
      .downloadPage .item1 .s-nothing {
        font-size: 0.875rem; } }
  .downloadPage .item1 .showme {
    display: block; }

@media (min-width: 1199px) {
  .downloadPage .container {
    max-width: 1054px; } }

.globalPage .item1 {
  margin-top: 120px;
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .globalPage .item1 {
      margin-top: 67px;
      margin-bottom: 15px; } }
  .globalPage .item1 ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start; }
    .globalPage .item1 ul li {
      width: calc((100% - 6%) / 3);
      margin-bottom: 48px; }
      @media (min-width: 991px) {
        .globalPage .item1 ul li:not(:nth-child(3n)) {
          margin-right: 3%; } }
      @media (max-width: 991px) {
        .globalPage .item1 ul li {
          width: 48%; }
          .globalPage .item1 ul li:nth-child(odd) {
            margin-right: 4%; } }
      .globalPage .item1 ul li a {
        display: flex;
        align-items: center; }
        @media (min-width: 768px) {
          .globalPage .item1 ul li a:hover .img-bx::before {
            opacity: 1; }
          .globalPage .item1 ul li a:hover .img-bx .more-bx {
            opacity: 1; } }
        .globalPage .item1 ul li a .img-bx {
          border-radius: 50%;
          position: relative;
          box-shadow: 0px 0px 8px #0000001F;
          min-width: 65px; }
          @media (max-width: 767px) {
            .globalPage .item1 ul li a .img-bx {
              min-width: 53px;
              max-width: 53px; } }
          .globalPage .item1 ul li a .img-bx::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            transition: .4s;
            opacity: 0; }
          .globalPage .item1 ul li a .img-bx .more-bx {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 0.875rem;
            line-height: 1.3;
            letter-spacing: 0px;
            font-weight: 700;
            color: #FFFFFF;
            opacity: 0;
            transition: .4s;
            text-align: center; }
        .globalPage .item1 ul li a .name {
          font-size: 1.125rem;
          line-height: 1.5;
          letter-spacing: 0px;
          font-weight: 700;
          color: #000000;
          transition: .4s;
          margin-left: 27px;
          max-height: 54px;
          overflow: hidden; }
          @media (max-width: 991px) {
            .globalPage .item1 ul li a .name {
              margin-left: 13px; } }
          @media (max-width: 767px) {
            .globalPage .item1 ul li a .name {
              font-size: 0.875rem;
              max-height: 44px; } }

@media (min-width: 1199px) {
  .globalPage .container {
    max-width: 1054px; } }

.globaldetail .banner-bx {
  position: relative; }
  .globaldetail .banner-bx .banerback-btn {
    width: 190px;
    height: 45px;
    background-color: #DA0E2B;
    position: absolute;
    left: 160px;
    bottom: 45px; }
    @media (max-width: 1199px) {
      .globaldetail .banner-bx .banerback-btn {
        left: 80px; } }
    @media (max-width: 767px) {
      .globaldetail .banner-bx .banerback-btn {
        left: 20px;
        bottom: 80px;
        width: 150px;
        height: 30px; } }
    .globaldetail .banner-bx .banerback-btn a {
      font-size: 1rem;
      line-height: 2.9;
      letter-spacing: 0px;
      font-weight: 400;
      color: #ffffff;
      display: block;
      height: 100%;
      position: relative;
      padding-left: 67px; }
      @media (max-width: 767px) {
        .globaldetail .banner-bx .banerback-btn a {
          font-size: 0.75rem;
          padding-left: 47px;
          line-height: 2.5; } }
      .globaldetail .banner-bx .banerback-btn a span {
        padding-left: 25px;
        position: relative;
        transition: .4s; }
      .globaldetail .banner-bx .banerback-btn a span::before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 1px;
        background-color: #ffffff;
        transition: .4s; }
      @media (min-width: 768px) {
        .globaldetail .banner-bx .banerback-btn a:hover span {
          padding-left: 35px; }
          .globaldetail .banner-bx .banerback-btn a:hover span::before {
            width: 30px; } }
  .globaldetail .banner-bx .banerback-btn2 {
    width: 190px;
    height: 45px;
    background-color: #DA0E2B;
    position: absolute;
    left: 400px;
    bottom: 45px; }
    @media (max-width: 1199px) {
      .globaldetail .banner-bx .banerback-btn2 {
        left: 320px; } }
    @media (max-width: 767px) {
      .globaldetail .banner-bx .banerback-btn2 {
        left: 20px;
        bottom: 140px;
        width: 150px;
        height: 30px; } }
    .globaldetail .banner-bx .banerback-btn2 a {
      font-size: 1rem;
      line-height: 2.9;
      letter-spacing: 0px;
      font-weight: 400;
      color: #ffffff;
      display: block;
      height: 100%;
      position: relative;
      text-align: right;
      padding-right: 27px; }
      @media (max-width: 767px) {
        .globaldetail .banner-bx .banerback-btn2 a {
          font-size: 0.75rem;
          line-height: 2.5;
          padding-right: 18px; } }
      .globaldetail .banner-bx .banerback-btn2 a span {
        padding-right: 25px;
        position: relative;
        transition: .4s; }
      .globaldetail .banner-bx .banerback-btn2 a span::before {
        position: absolute;
        content: '';
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 1px;
        background-color: #ffffff;
        transition: .4s; }
      @media (min-width: 768px) {
        .globaldetail .banner-bx .banerback-btn2 a:hover span {
          padding-right: 35px; }
          .globaldetail .banner-bx .banerback-btn2 a:hover span::before {
            width: 30px; } }

.bgcover {
  width: 100%;
  height: 0;
  background: 50%/cover; }

.mo {
  display: none; }
  @media (max-width: 767px) {
    .mo {
      display: block; } }

.pc {
  display: none; }
  @media (min-width: 768px) {
    .pc {
      display: block; } }

.container {
  padding: 0 15px;
  margin: 0 auto;
  width: 100%; }
  @media (max-width: 767px) {
    .container {
      padding: 0 20px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1230px; } }

#top-menu-ul {
  margin: 0 auto 10px;
  position: relative;
  z-index: 1; }
  #top-menu-ul .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 41px;
    border-bottom: 1px solid #E4E4E4; }
  #top-menu-ul .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: left; }
    #top-menu-ul .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    #top-menu-ul .item_menu_Box li {
      height: 41px; }
      #top-menu-ul .item_menu_Box li:nth-child(1) a {
        margin-left: 0; }
    #top-menu-ul .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 0px;
      border-bottom: 3px solid rgba(255, 255, 255, 0);
      font-size: 1.125rem;
      color: #000000;
      transition: .2s;
      font-weight: 700;
      margin: 0 20px;
      padding: 10px 20px 10px 0; }
      #top-menu-ul .item_menu_Box li a::before {
        position: absolute;
        content: '';
        top: 50%;
        right: 0;
        width: 10px;
        height: 1px;
        background-color: #000000;
        transform: translateY(-50%);
        transition: .2s; }
      #top-menu-ul .item_menu_Box li a::after {
        position: absolute;
        content: '';
        top: 50%;
        right: 0;
        width: 10px;
        height: 1px;
        background-color: #000000;
        transform: translateY(-50%) rotate(90deg);
        transition: .2s; }
      @media (max-width: 767px) {
        #top-menu-ul .item_menu_Box li a {
          margin: 0 15px; } }
    #top-menu-ul .item_menu_Box li.active a {
      border-color: #DA0E2B;
      color: #DA0E2B; }
      #top-menu-ul .item_menu_Box li.active a::before {
        background-color: #DA0E2B; }
      #top-menu-ul .item_menu_Box li.active a::after {
        opacity: 0; }
    @media (min-width: 768px) {
      #top-menu-ul .item_menu_Box li a:hover {
        border-color: #DA0E2B;
        color: #DA0E2B; }
        #top-menu-ul .item_menu_Box li a:hover::before {
          background-color: #DA0E2B; }
        #top-menu-ul .item_menu_Box li a:hover::after {
          transform: rotate(0deg);
          opacity: 0; } }

.flex-direction-nav {
  display: none; }
  .flex-direction-nav a {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 10;
    background-color: #000000; }
    .flex-direction-nav a:after {
      position: absolute;
      top: 0; }
  .flex-direction-nav a.flex-next {
    background: url(../images/icon_arrow_mb.png) no-repeat center/10px;
    right: -40px; }
  .flex-direction-nav a.flex-prev {
    background: url(../images/icon_arrow_mb.png) no-repeat center/10px;
    left: -40px;
    transform: translateY(-50%) rotate(180deg); }

.open_flexslider .flex-direction-nav {
  display: block; }
  @media (max-width: 767px) {
    .open_flexslider .flex-direction-nav {
      display: none; } }

#top-menu-ul2 {
  margin: 0 auto 50px;
  position: relative;
  z-index: 1; }
  #top-menu-ul2 .item_Menu {
    margin: 0 auto;
    overflow: hidden;
    height: 41px; }
  #top-menu-ul2 .item_menu_Box {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    position: relative;
    text-align: left; }
    #top-menu-ul2 .item_menu_Box .item_menu_list {
      white-space: nowrap !important;
      padding-bottom: 0px;
      text-align: center;
      font-size: 0px;
      display: inline-flex; }
    #top-menu-ul2 .item_menu_Box li {
      height: 41px; }
      #top-menu-ul2 .item_menu_Box li:nth-child(1) a {
        margin-left: 0; }
    #top-menu-ul2 .item_menu_Box li a {
      position: relative;
      display: block;
      line-height: 1;
      letter-spacing: 0px;
      font-size: 0.9375rem;
      color: #000000;
      transition: .2s;
      margin: 0 25px;
      padding: 13px 20px 10px 0; }
      @media (max-width: 767px) {
        #top-menu-ul2 .item_menu_Box li a {
          margin: 0 15px; } }
    #top-menu-ul2 .item_menu_Box li.active a {
      color: #DA0E2B; }
    @media (min-width: 768px) {
      #top-menu-ul2 .item_menu_Box li a:hover {
        color: #DA0E2B; } }

.rh-btn {
  text-align: center; }
  .rh-btn .link {
    padding-right: 25px;
    position: relative;
    transition: .4s;
    display: inline-block; }
    .rh-btn .link span {
      font-size: 0.875rem;
      line-height: 2;
      letter-spacing: 0px;
      font-weight: 500;
      color: #A1A1A1;
      position: relative;
      transition: .4s;
      display: block; }
      @media (max-width: 767px) {
        .rh-btn .link span {
          font-size: 0.75rem;
          line-height: 1.5; } }
    .rh-btn .link::before {
      position: absolute;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 1px;
      background-color: #A1A1A1;
      transition: .4s; }
    @media (min-width: 767px) {
      .rh-btn .link:hover span {
        transform: translateX(-15px);
        color: #DA0E2B;
        font-weight: 700; }
      .rh-btn .link:hover::before {
        width: 34px;
        background-color: #DA0E2B; } }

.news-list ul li:not(:last-child) {
  margin-bottom: 18px; }

@media (min-width: 767px) {
  .news-list ul li a:hover .link span {
    transform: translateX(-15px);
    color: #DA0E2B;
    font-weight: 700; }
  .news-list ul li a:hover .link::before {
    width: 34px;
    background-color: #DA0E2B; } }

.news-list ul li a .date {
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: 0px;
  font-weight: 400;
  color: #DA0E2B; }
  @media (max-width: 767px) {
    .news-list ul li a .date {
      font-size: 0.75rem;
      line-height: 1.4; } }

.news-list ul li a .des-bx {
  display: flex;
  justify-content: space-between; }
  .news-list ul li a .des-bx .l-bx {
    width: 57%;
    max-height: 55px;
    overflow: hidden;
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: 0px;
    font-weight: 400;
    color: #000000; }
    @media (max-width: 767px) {
      .news-list ul li a .des-bx .l-bx {
        font-size: 0.875rem;
        max-height: 40px;
        width: 64%; } }

.sh-banner {
  width: 100vw;
  height: 480px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .sh-banner {
      padding-bottom: 62.5%;
      height: 0; } }
  .sh-banner .bgcover {
    width: 100vw;
    height: 480px;
    background-size: cover !important; }
    @media (max-width: 767px) {
      .sh-banner .bgcover {
        padding-bottom: 62.5%;
        height: 0; } }
  .sh-banner h1 {
    display: none; }

.sh-productlist {
  display: flex;
  flex-wrap: wrap;
  justify-content: start; }
  @media (max-width: 767px) {
    .sh-productlist {
      justify-content: space-between; } }
  .sh-productlist li {
    width: 32%;
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .sh-productlist li:not(:nth-child(3n)) {
        margin-right: 2%; } }
    @media (max-width: 767px) {
      .sh-productlist li {
        width: 48%; }
        .sh-productlist li:last-child {
          margin-bottom: 65px; } }
    .sh-productlist li:nth-child(3n-1) {
      transition-delay: .4s; }
    .sh-productlist li:nth-child(3n) {
      transition-delay: .8s; }
    @media (max-width: 450px) {
      .sh-productlist li {
        width: 100%;
        margin-bottom: 80px; } }
    .sh-productlist li a {
      display: block; }
      .sh-productlist li a .img-bx {
        width: 100%;
        padding-bottom: 100%;
        position: relative; }
        .sh-productlist li a .img-bx .unh {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: .4s;
          opacity: 1; }
        .sh-productlist li a .img-bx .h {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: .4s;
          opacity: 0; }
      .sh-productlist li a .des {
        font-size: 1rem;
        line-height: 1.5;
        letter-spacing: 0px;
        font-weight: 300;
        color: #242424;
        transition: .4s; }
      .sh-productlist li a .ti {
        font-size: 1.125rem;
        line-height: 1.5;
        letter-spacing: 0px;
        font-weight: 700;
        color: #242424;
        transition: .4s; }
      @media (min-width: 768px) {
        .sh-productlist li a:hover .des {
          color: #DA0E2B; }
        .sh-productlist li a:hover .ti {
          color: #DA0E2B; }
        .sh-productlist li a:hover .img-bx .unh {
          opacity: 0; }
        .sh-productlist li a:hover .img-bx .h {
          opacity: 1; } }

.sh-backbtn {
  display: inline-block; }
  .sh-backbtn .back-bx {
    display: flex;
    align-items: center; }
    .sh-backbtn .back-bx .line {
      position: relative;
      width: 16px;
      transition: .4s;
      margin-right: 8px; }
      .sh-backbtn .back-bx .line::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #A1A1A1;
        transform: translateY(-50%);
        transition: .4s; }
    .sh-backbtn .back-bx .tx {
      font-size: 0.875rem;
      line-height: 2;
      letter-spacing: 0px;
      font-weight: 400;
      color: #A1A1A1;
      transition: .4s; }
      @media (max-width: 767px) {
        .sh-backbtn .back-bx .tx {
          font-size: 0.75rem; } }
  @media (min-width: 768px) {
    .sh-backbtn:hover .back-bx .line {
      width: 30px; }
      .sh-backbtn:hover .back-bx .line::before {
        background-color: #DA0E2B; }
    .sh-backbtn:hover .back-bx .tx {
      color: #DA0E2B; } }

.s-nothing {
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: 0px;
  font-weight: 400;
  color: #242424; }

.in_fade {
  opacity: 0;
  transition: 1s cubic-bezier(0.5, 1, 0.89, 1);
  top: 8px;
  position: relative; }

.in_fade.active {
  opacity: 1;
  top: 0; }
