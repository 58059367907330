.header_show.chheader{
    background:#ffffff;
    .logo{
        width: 149px;
        height: 48px;
        @media (max-width:991px) {
            width: 86px;
            height: 49px;
        }
    }
    .nav_box a{
        @media (min-width:768px) {
            color: rgba(#000000, .5);
            &:hover{
                color:#DA0E2B ;
            }
        }
    }
    .nav_box a.active{
        color:#DA0E2B ;
    }
    .hamburger-menu:before,.hamburger-menu:after,.hamburger-menu span{
            background-color: #a1a1a1;
    }
    .header_box.open{
        .logo{
        }
       
    }
    .sear-bx{
        .opse{
            &::before{
                background: url(../images/icon_search2.png) no-repeat center center / cover;
            }
            &::after{
                background: url(../images/header_cancel2.png) no-repeat center center / cover;
            }
        }
        input{
            border-bottom: 1px solid rgba(#000000, .5);
            color: #a1a1a1;
            @media (max-width: 991px){
                border-bottom: 1px solid #a1a1a1;
            }
        }
    }
}
.indexPage{
    .banner-bx{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: relative;
        .slick-dots{
            margin: -90px -145px 0;
            text-align: right;
            @media (max-width:991px) {
                margin: -39px -5px 0;
            }
        }
        .slick-dots li button::before{
            width: 0;
        }
        .slick-dots li button{
            @include fontset(14,1.5,0,400,#ffffff);
            font-family: $font_content;
            opacity: .3;
            transition: .4s;
            width: 65px;
            position: relative;
            padding: 0;
            text-align: left;
            @media (max-width:991px) {
                width: 44px;
                font-size: pxToEm(12);
            }
        }
        .slick-dots li button{
            &::before{
                position: absolute;
                left: auto;
                right: 0;
                width: 44px;
                height: 1px;
                top: 50%;
                transform: translateY(-50%);
                transition: .4s;
                background-color: rgba(255,255,255,.3);
                @media (max-width:991px) {
                    width: 22px;
                }
            }
        }
        .slick-dots li:last-child button{
            &::before{
                opacity: 0;
            }
        }
        .slick-dots li{
            position: relative;
            width: auto;
            margin: 0;
            margin-right: 5px;
            
          
        }
        .slick-dots li.slick-active button{
            opacity: 1;
        }
        .slick-dots li.slick-active button{
            opacity: 1;
            width: 151px;
            @media (max-width:991px) {
                width: 82px;
            }
            &::before{
                width: 132px;
                background-color: #ffffff;
                opacity: 1;
                @media (max-width:991px) {
                    width: 60px;
                }
            }
        }
        .bgcover{
            background-size: cover !important;
        }
        .pc-banner{
            width: 100vw;
            height: 100vh;
            // position: relative;
            display: none;
            @media (min-width:768px) {
                display: block;
            }
            .bgcover{
                width: 100vw;
                height: 100vh;
            }
        }
        .mo-banner{
            width: 100vw;
            height: 100vh;
            display: none;
            @media (max-width:767px) {
                display: block;
            }
            .bgcover{
                width: 100vw;
                height: 100vh;
            }
        }
        .scroll-line{
            position: absolute;
            left: 160px;
            bottom: 75px;
            opacity: 1;
            z-index: 9;
            height: 164px;
            width: 28px;
            display: block;
            transition: none;
            @media (max-width:1350px) {
                left: 30px;
            }
            @media (max-width:991px) {
                bottom: 20px;
                height: 92px;
                left: 14px;
            }
            &::before{
                position: absolute;
                content: '';
                bottom: 14px;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: 49.6%;
                background-color: #A1A1A1;
                transition: .4s;
                @media (max-width:991px) {
                    height: 22.6%;
                    bottom: 7px;
                }
            }
            &::after{
                position: absolute;
                content: '';
                bottom: 0;
                width: 28px;
                height: 28px;
                border: 1px solid #A1A1A1;
                border-radius: 50%;
                transition: .4s;
                animation: cirani 2s 1s linear infinite;
                left: 50%;
                transform: translateX(-50%);
                @media (max-width:991px) {
                   width: 14px;
                   height: 14px;
                   animation: cirmoani 1s 1s linear infinite;
                }
            }
            @keyframes cirani {
                0%{
                    bottom: 0;
                    opacity: 1;
                }
                100%{
                    bottom: 70px;
                    opacity: 0;
                }
            }
            @keyframes cirmoani {
                0%{
                    bottom: 0;
                    opacity: 1;
                }
                100%{
                    bottom: 15px;
                    opacity: 0;
                }
            }
            span{
                font-size: pxToEm(13);
                writing-mode: vertical-rl;
                color: #A1A1A1;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%) rotate(180deg);
                transition: .4s;
                @media (max-width:991px) {
                    font-size: 11px;
                }
            }
        }
    }
    .video-bx{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: relative;
        @media (max-width:767px) {
            background-color: #000000;
        }
        #player{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 120%!important;
            height: 120%!important;
            min-width: 1440px;
            pointer-events: none;
            @media (max-width:767px) {
                width: 90% !important;
                height: 35% !important;
                min-width: auto;
            }
        }
        .bgcover{
            width: 100vw;
            height: 100vh;
            background-size: cover !important;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 80;
        }
        .star-btn{
            width: 72px;
            height: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 85;
            background: url(../images/icon_yt.png) no-repeat center center/ cover;
        }
        .scroll-line{
            position: absolute;
            left: 160px;
            bottom: 75px;
            opacity: 1;
            z-index: 9;
            height: 164px;
            width: 28px;
            display: block;
            transition: none;
            @media (max-width:1350px) {
                left: 30px;
            }
            @media (max-width:991px) {
                bottom: 20px;
                height: 92px;
                left: 14px;
            }
            &::before{
                position: absolute;
                content: '';
                bottom: 14px;
                left: 50%;
                transform: translateX(-50%);
                width: 1px;
                height: 49.6%;
                background-color: #A1A1A1;
                transition: .4s;
                @media (max-width:991px) {
                    height: 22.6%;
                    bottom: 7px;
                }
            }
            &::after{
                position: absolute;
                content: '';
                bottom: 0;
                width: 28px;
                height: 28px;
                border: 1px solid #A1A1A1;
                border-radius: 50%;
                transition: .4s;
                animation: cirani 2s 1s linear infinite;
                left: 50%;
                transform: translateX(-50%);
                @media (max-width:991px) {
                   width: 14px;
                   height: 14px;
                   animation: cirmoani 1s 1s linear infinite;
                }
            }
            @keyframes cirani {
                0%{
                    bottom: 0;
                    opacity: 1;
                }
                100%{
                    bottom: 70px;
                    opacity: 0;
                }
            }
            @keyframes cirmoani {
                0%{
                    bottom: 0;
                    opacity: 1;
                }
                100%{
                    bottom: 15px;
                    opacity: 0;
                }
            }
            span{
                font-size: pxToEm(13);
                writing-mode: vertical-rl;
                color: #A1A1A1;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%) rotate(180deg);
                transition: .4s;
                @media (max-width:991px) {
                    font-size: 11px;
                }
            }
        }
    }
  
    .item1{
        margin-top: 120px;
        margin-bottom: 110px;
        @media (max-width:991px) {
            margin-top: 60px;
            margin-bottom: 55px;
        }
        .it1-bx{
            text-align: center;
            max-width: 820px;
            margin: 0 auto;
            width: 100%;
            .ti{
                @include fontset(28,1.5,0,700,#000000);
                margin-bottom: 30px;
                @media (max-width:767px) {
                    font-size: pxToEm(21);
                    margin-bottom: 20px;
                }
            }
            .des{
                @include fontset(16,1.5,0,400,#A1A1A1);
                span{
                    display: block;
                    color: #000000;
                    font-weight: 400;
                    @media (max-width:767px) {
                        display: inline;
                        font-weight: 400;
                    }
                }
                margin-bottom: 50px;
                @media (max-width:767px) {
                    font-size: pxToEm(12);
                    margin-bottom: 25px;
                }
            }
        }
    }
    .item2{
        .it-bx{
            background: url(../images/b_product_bg.jpg) no-repeat center center / cover;
            @include fontset(28,1,0,700,#000000);
            display: block;
            width: 100%;
            margin-bottom: 15px;
            @media (max-width:767px) {
                padding-bottom: 30px;
                background: url(../images/b_product_bg_mb.jpg) no-repeat center center / cover;
                margin-bottom: 7px;
            }
            &:nth-child(even){
                .l-bx{
                    order: 2;
                }
                .r-bx{
                    order: 1;
                }
            }
            @media (min-width:767px) {
                &:hover{
                    background: url(../images/b_product_bg_h.jpg) no-repeat center center / cover;
                    .link{
                        span{
                            transform: translateX(-15px);
                            color: #DA0E2B;
                            font-weight: 700;
                        }
                        &::before{
                            width: 34px;
                            background-color: #DA0E2B;
                        }
                    }
                }
            }
            .ins-bx{
                display: flex;
                @media (max-width:1199px) {
                    max-width: 100%;
                }
                @media (max-width:767px) {
                    display: block;
                }
            }
            .l-bx{
                @media (max-width:767px) {
                    margin-bottom: 10px;
                }
                img{
                    @media (max-width:991px) {
                        width: 100%;
                    }
                }
            }
            .r-bx{
                position: relative;
                width: 50%;
                @media (max-width:767px) {
                    width: 100%;
                }
                .tx-bx{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    text-align: center;
                    transform: translate(-50%,-50%);

                    .ti{
                        @include fontset(28,1,0,700,#000000);
                        margin-bottom: 52px;
                        @media (max-width:767px) {
                            font-size: pxToEm(21);
                            margin-bottom: 5px;
                        }
                    }
                    @media (max-width:767px) {
                        position: relative;
                        top: auto;
                        left: auto;
                        transform: none;
                    }
                }
            }
        }
    }
    .item3{
        margin-top: 125px;
        margin-bottom: 65px;
        @media (max-width:767px) {
            margin-top: 62px;
            margin-bottom: 70px;
        }
        .container{
            @media (min-width:1199px) {
                max-width: 1054px;
            }
        }
        .it3-bx{
            .ti-bx{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 45px;
                @media (max-width:767px) {
                    margin-bottom: 22px;
                }
                .l-ti{
                    @include fontset(28,1.5,0,700);
                    @media (max-width:767px) {
                        font-size: pxToEm(21);
                        line-height: 1.1;
                    }
                }
                .r-more{
                    a{
                        @include fontset(14,1.2,0,700);
                        padding-right: 15px;
                        position: relative;
                        @media (max-width:767px) {
                            font-size: pxToEm(12);
                        }
                        &::before{
                            position: absolute;
                            content: '';
                            right: 0;
                            background: url(../images/icon_arrow_mb.png) no-repeat center / 5px ;
                            width: 5px;
                            height: 10px;
                            top: 6px;
                            @media (max-width:767px) {
                                top: 3px;
                            }
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            left: 0;
                            width: 38px;
                            height: 1px;
                            background-color: #DA0E2B;
                            transition: .4s;
                            bottom: 1px;
                            @media (max-width:767px) {
                                width: 33px;
                            }
                        }
                        @media (min-width:767px) {
                            &:hover{
                                color: #DA0E2B;
                                &::after{
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .item4{
        background: url(../images/c_img.jpg) no-repeat center center / cover;
        @media (max-width:767px) {
            background: url(../images/c_img_mb.jpg) no-repeat center center / cover;
        }
        .container{
            @media (min-width:1199px) {
                max-width: 1054px;
            }
        }
        .it4-bx{
            padding-bottom: 25%;
            position: relative;
            @media (max-width:767px) {
                padding-bottom: 104%;
            }
            .tx-bx{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 50%;
                @media (max-width:767px) {
                    top: auto;
                    bottom: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                    text-align: center;
                }
                .ti{
                    @include fontset(28,1,0,700);
                    margin-bottom: 15px;
                    @media (max-width:767px) {
                        font-size: pxToEm(21);
                        margin-bottom: 7px;
                    }
                }
                .des{
                    @include fontset(16,1.5,0,400);
                    margin-bottom: 50px;
                    @media (max-width:767px) {
                        font-size: pxToEm(12);
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .r-more{
            a{
                @include fontset(14,1.2,0,700);
                padding-right: 15px;
                position: relative;
                @media (max-width:767px) {
                    font-size: pxToEm(12);
                }
                &::before{
                    position: absolute;
                    content: '';
                    right: 0;
                    background: url(../images/icon_arrow_mb.png) no-repeat center / 5px ;
                    width: 5px;
                    height: 10px;
                    top: 6px;
                    @media (max-width:767px) {
                        top: 3px;
                    }
                }
                &::after{
                    position: absolute;
                    content: '';
                    left: 0;
                    width: 47px;
                    height: 1px;
                    background-color: #DA0E2B;
                    transition: .4s;
                    bottom: 1px;
                    @media (max-width:767px) {
                        width: 40px;
                    }
                }
                @media (min-width:767px) {
                    &:hover{
                        color: #DA0E2B;
                        &::after{
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}