/*----------------------header------------------------*/

.header_show {
    transition: .4S;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0);
    z-index: 10;
    li {
        list-style: none;
    }
    &.active{
        background: rgba(#131314,1);
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 100px;
    padding: 0 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: pxToEm(14);
    transition: .4s;
    @media (max-width:1199px) {
        padding: 0 65px;
    }
    @media (max-width:1400px) {
        padding:  0 55px;
    }
    // background: url(../images/bavv.jpg) top left no-repeat;
}



.logo {
    width: 184px;
    height: 60px;
    background: url(../images/header_logo.png) center center no-repeat;
    background-size: contain;
    text-indent: -10000px;
    transition: .4s ease;
    a {
        @include aLink();
    }
}


/*----------------------nav------------------------*/

.nav_box {
    position: relative;
  
    ul {
        display: flex;
        li{
            position: relative;
            text-align: center;
            width: auto;
            transition: .4s;
            transition-delay:.4s;
            pointer-events: auto;
        }
        li:not(:first-child){
            margin-right: 56px;
            a{
                &::before{
                    width: 100%;
                }
            }
        }
    }
    ul.opense{
        li:not(:first-child){
            opacity: 0;
            transition-delay: 0s;
            pointer-events: none;
        }
    }
    a.active{
        color: #DA0E2B;
        &::before{
            @media (min-width:992px) {
                opacity: 1;
            }
        }
        span{
            @media (max-width:991px) {
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    bottom: 1px;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: #DA0E2B;
                }
            }
        }
    }
    a{
        width: 100%;
        display: block;
        color: rgba(#FFFFFF,.5);
        transition: .4s;
        font-size: pxToEm(14);
        @media (max-width:991px){
            font-size: pxToEm(12);
            font-weight: 700;
        }
        &::before{
            position: absolute;
            content: '';
            bottom: 0px;
            height: 1px;
            background-color: #DA0E2B;
            transition: .4s;
            opacity: 0;
        }
        &:hover{
            text-decoration: none;
        
        }
        @media (min-width:768px) {
            &:hover{
                color: #DA0E2B;
                &::before{
                    opacity: 1;
                }
            }
        }
    }
}
.sear-bx{
    order: 6;
    position: relative;
    @media (max-width:991px) {
        padding: 21px 0;
    }
    .opse{
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 8;
        @media (max-width:991px) {
            display: none;
        }
        &::before{
            position: absolute;
            content: '';
            width: 24px;
            height: 24px;
            background: url(../images/icon_search.png) no-repeat center center / cover;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            opacity: 1;
            transition: opacity .4s .4s;
            // transition-delay: .4s;
        }
        &::after{
            position: absolute;
            content: '';
            width: 18px;
            height: 18px;
            background: url(../images/header_cancel.png) no-repeat center center / cover;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            opacity: 0;
            transition: .4s;
        }
    }
    .moopse{
        display: block;
        width: 40px;
        height: 40px;
        position: absolute;
        right: -11px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 8;
        padding: 0;
        @media (min-width:992px) {
            display: none;
        }
        &::before{
            position: absolute;
            content: '';
            width: 24px;
            height: 24px;
            background: url(../images/icon_search2.png) no-repeat center center / cover;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            opacity: 1;
            transition: opacity .4s .4s;
            // transition-delay: .4s;
        }
    }
    input{
        height: 28px;
        position: absolute;
        right: -9px;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(255, 255, 255, 0);
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #ffffff;
        width: 0px;
        transition: .4s;
        z-index: 5;
        color: #ffffff;
        @media (max-width:991px) {
            border-bottom: 1px solid #A1A1A1;
            width: 100%;
            right: 0;
            padding-right: 15%;
            color: #a1a1a1;
        }
    }
    @media (max-width:991px) {
        ::-webkit-input-placeholder {
            /* WebKit browsers */
            color: rgba(#A1A1A1,.5);
            line-height: 18px;
            font-family: $font_content;
            font-size: pxToEm(12);
        }
        
        :-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: rgba(#A1A1A1,.5);
            line-height: 43px;
            font-family: $font_content;
            font-size: pxToEm(12);
        }
        
        ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: rgba(#A1A1A1,.5);
            line-height: 43px;
            font-family: $font_content;
            font-size: pxToEm(12);
        }
        
        :-ms-input-placeholder {
            /* Internet Explorer 10+ */
            color: rgba(#A1A1A1,.5);
            line-height: 18px;
            font-family: $font_content;
            font-size: pxToEm(12);
        }
        
    }
}
.sear-bx.clse{
    .opse{
        &::before{
            opacity: 0;
            transition-delay: 0s;
        }
        &::after{
            opacity: 1;
            transition-delay: .4s;
        }
    }
    input{
        width: 360px;
        transition-delay: .4s;
    }
}

/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 24px;
$bar-height: 2px;
$bar-spacing: 10px;
$bar-color:rgba(#ffffff,.5);
.menu-wrapper {
    display: none;
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 10;
}

.hamburger-menu:after,
.hamburger-menu:before, {
    width: $bar-width;
    height: $bar-height;
    @include border();
}
.hamburger-menu span{
    width: $bar-width;
    height: $bar-height;
    position: absolute;
    right: 8px;
    top: 6.5px;
    background: $bar-color;
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: $bar-color;
    transition: all 0ms 300ms;
    @include border(99px);
    &.animate {
        background: rgba(255, 255, 255, 0);
    }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    right: 8px;
    bottom: 0px;
    background: $bar-color;
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 15px;
    background: $bar-color;
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu.animate:after {
    top: 8px;
    transform: rotate(-45deg);
    transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    background-color: #A1A1A1;
    @include border(99px);
    width: 25px;
}

.hamburger-menu.animate:before {
    bottom: -10px;
    transform: rotate(45deg);
    transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    background-color: #A1A1A1;
    @include border(99px);
}
.hamburger-menu.animate span{
    opacity: 0;
}

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}




@media (max-width: 991.98px){
    $header_heright: 54px;

    .logo{
        width: 86px;
        height: 49px;
    }
    .header_show{
    }
    .header_box.open{
        background-color: #ffffff;
        .logo{
            background: url(../images/header_logo.png) no-repeat center center / contain;
        }
    }
    .header_box {
        width: 100%;
        height:  $header_heright;
        font-size: pxToEm(17);
        margin: 0 auto;
        padding: 0 20px;
        border-bottom: none;
    }
    .nav_box nav{
        width: 100%;
        margin: auto;
        padding: 0 30px;
    }
    .nav_box {
        display: none;
        position: fixed;
        top:  53px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        background: #ffffff;
        ul {
            display: block;
            margin-top: 20px;
            li {
                width: 100%;
                a {
                    padding: 21px 0px;
                    text-align: left;
                    text-decoration: none;
                    color: #A1A1A1;
                    letter-spacing: 0;
                }
            }
        }
        ul>li:not(:last-child) {
            margin-right: 0;
        }

    }
    .menu-wrapper {
        display: block;
    }

}


