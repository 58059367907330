/*----------------------通用設定------------------------*/


//選取顏色
p,
h1,
h2,
h3,
h4,
b,
strong,
span,
li,
div,
a,
img,
tr,
td,
th {
    &::selection {
        color: $white;
        background: #da0e2b;
    }
}

body {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-height: 100%;
    line-height: 1em;
    overflow: auto;
    font-size: 16px;
    color: #000000;
    word-break: break-word;
    letter-spacing: 0.1em;
    z-index: 0;
    background: #ffffff;
    -webkit-text-size-adjust: 100%;
    opacity: 0;
    transition: 1.2s;
    font-family: $font_content;
}
body.loading{
    opacity: 1;
    transition: opacity 1.2s;
}

//a tag
a {
    text-decoration: none;
    outline: 0;
}

a,
a:hover {
    transition: 0.4s;
}

img {
    vertical-align: middle;
    max-width: 100%;
}

//phone
a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

a[href^="tel"] {
    color: inherit;
    /* Inherit text color of parent element. */
    text-decoration: none;
    /* Remove underline. */
    /* Additional css `propery: value;` pairs here… */
}

//border-box
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

//jqimgFill 
.jqimgFill {
    position: relative;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: inline-block;
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}

.jqimgFill img,
.jqimgFill-tc img {
    left: 0;
    top: 0;
    margin: 0;
    display: block;
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    max-height: none;
    max-width: none;
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}

// input,select,textarea
input,
select,
textarea {
    width: 100%;
    height: 56px;
    outline: none;
    padding: 0;
    color: $black;

    border: 1px solid $white;
    font-size: pxToEm(16);
    border-radius: 5px;

    @include screen(640px) {
        font-size: pxToEm(15);
    }

    &:focus {
        transition: .4s;
        border: 1px solid #eee;
    }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background: #000 !important;
}

textarea {
    height: 218px;
    padding: 10px;
}

// input placeholder
::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #666;
    line-height: 18px;
    font-family: $font_content;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #666;
    line-height: 43px;
    font-family: $font_content;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #666;
    line-height: 43px;
    font-family: $font_content;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #666;
    line-height: 18px;
    font-family: $font_content;
}

//移除 input type="date"箭頭
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type='date'],
input[type='time'] {
    -webkit-appearance: none;
}

select {
    font-family: $font_content;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    //background: url(../svg/arrw_1.svg?20181225) no-repeat scroll 96.9% center transparent;
    background-size: 3.4%;
    padding-right: 14px;
    height: 40px;
    width: 100%;
}

select::-ms-expand {
    display: none;
}

.selectBox {
    background: #FFF;
}

// header fix狀態時 錨點用
body :target:before {
    display: block;
    content: "";
    height: 120px;
    margin: -120px 0 0;

    @media screen and (max-width: 960px) {
        height: 100px;
        margin: -100px 0 0;
    }
}

body .slide-down :target:before {
    display: block;
    content: "";
    height: 120px;
    margin: -120px 0 0;
}

.slick-slide {
    outline: 0;
}






/*----------------------大區塊------------------------*/

#Wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    // min-height: 100%;
    // min-width: 320px !important;
    overflow: hidden;
}

main[role="main"] {
    display: block;
    width: 100%;
    @extend %cf;
    position: relative;
    z-index: 1;
}

section {
    position: relative;
    @extend %cf;
}

article {
    @include secction_width(1200px, 85%);
    position: relative;
    z-index: 1;
}


/*----------------------每個選單Highlight------------------------*/




/*----------------------內頁單元標題------------------------*/

.title_box {}

/*----------------------tag_list------------------------*/
.tag_list {
    a {
        color: $white;
        text-decoration: none;
        @include border(10px);
        background: #434343;
        padding: 3px 7px;
        font-size: pxToEm(15);

        &:not(:last-child) {
            margin-right: 15px;
        }

        &:hover {
            background: #2f3a88;
        }
    }
}

/*----------------------編輯器------------------------*/

.editor_Content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0 0;
    line-height: 1.5;
    letter-spacing: 0;
    font-weight: 400;
    header {
        width: 100%;
        padding-bottom: 31px;
        margin-bottom: 29px;
        border-bottom: solid 1px #7d7d7d;
        display: flex;

        h2 {
            width: 80%;
            font-size: pxToEm(20);
            line-height: 1.4;
            color: $white;
            padding-right: 2%;
        }

        .tag_list {
            text-align: right;
            @include calc(73%);
        }
    }
    ul,ol {
        padding-left: 40px;
    }
    ol{
      list-style: decimal;
    }
    ul{
      list-style: disc;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    b,strong{
      font-weight: bold;
    }
    strong{
        font-size: inherit;
    }
    em{
     font-style: italic; 
    }
    a{
      text-decoration: underline;
      color:#0782C1;
    }
    iframe {
        max-width: 100%;
    }
    h1,h2,h3,h4,h5,h6 {
        margin: inherit;
    }
    blockquote{
      font-style: italic;
      padding: 2px 20px 0 8px;
      margin-left: 40px;
      border-left:5px solid #ccc;
    }
    td, th, tr{
        margin: initial;
        padding: initial;
    }
    table{
        border-collapse: inherit;
    }
}

.editor_Box {
    margin: auto;
    line-height: 1.5;
    font-size: pxToEm(15);
    color: #000000;

    ul,
    ol {
        padding-left: 40px;
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    iframe {
        max-width: 100%;

        @media screen and (max-width: 767px) {
            height: auto !important;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: inherit;
    }

}

.mo_use {
    display: none;
}


/*----------------------back------------------------*/

.back_btn,
.more_btn {
    width: 100%;
    margin-top: 70px;
    font-size: pxToEm(18);

    a {
        margin: auto;
        display: inline-block;
        color: $white;
        text-decoration: none;
        padding: 19.5px 49px;
        background-color: $blue;
        @include border(5px);

        &:hover {
            background: $blue;
            opacity: .5;
        }
    }
}

/*----------------------上下則------------------------*/

.seesaw {
    position: relative;
    width: 100%;
    font-size: pxToEm(16);
    margin-top: 61px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: $orange;
        text-decoration: none;
    }

    .left_button:before,
    .right_button:before,
    .back_button {
        transition: 0.4s;
    }

    .left_button,
    .right_button {
        position: absolute;

        &:before {
            content: '';
            display: block;
            width: 70px;
            height: 2px;
            background: $orange;
            margin-bottom: 2px;
        }

        &:hover:before {
            width: 90px;
        }
    }

    .left_button {
        right: 0;
    }

    .right_button {
        left: 0;
        text-align: right;
    }

    .back_button {
        font-size: pxToEm(16);
        width: 230px;
        height: auto;
        padding: 11px 0;
        border: 1px solid $orange;
        text-align: center;

        &:hover {
            background: $orange;

            a {
                color: $white;
            }
        }
    }
}


/*----------------------GO TOP------------------------*/
.topbtn-bx{
    display: none;
    position: relative;
    z-index: 100;
}
.topbtn-bx.show-topbtn{
}
.top_btn {
    cursor: pointer;
    opacity: 1;
    position: fixed;
    bottom: 40px;
    right: 166px;
    z-index: 9;
    height: 164px;
    width: 28px;
    display: block;
    transition: none;
    @media (max-width:1400px) {
        right: 55px;
    }
    @media (max-width:991px) {
        right: 20px;
    }
    &::before{
        position: absolute;
        content: '';
        top: 14px;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 49.6%;
        background-color: #A1A1A1;
        transition: .4s;
    }
    &::after{
        position: absolute;
        content: '';
        top: 0;
        width: 28px;
        height: 28px;
        border: 1px solid #A1A1A1;
        border-radius: 50%;
        transition: .4s;
    }
    span{
        font-size: pxToEm(13);
        writing-mode: vertical-rl;
        color: #A1A1A1;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
        transition: .4s;
    }
    @media (min-width:991px) {
        &:hover{
            // height: 102px;
            &::before{
                background-color: #DA0E2B;
                height: 20%;
            }
            &::after{
                border-color:#DA0E2B;
            }
            span{
                color: #DA0E2B;
                // height: 100px;
                padding-top: 50px;
            }
        }
    }

    
    &.fix {
        position: absolute;
        transition: 0s;
        bottom:80px;

        @include screen(768px) {
            bottom: 30px;
            position: fixed;
        }
    }
    @media (max-width:767px) {
        width: 14px;
        height: 79px;
        bottom: 30px;
        &::before{
            height: 27%;
            top: 7px;
        }
        &::after{
            width: 14px;
            height: 14px;
        }
        span{
            font-size: 11px;
            letter-spacing: 0;
        }
    }
}


/*----------------------頁數------------------------*/
$lr_btn:17px;

%list_a {
    display: block;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.page {
    font-size: pxToEm(14);
    clear: both;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    @media (max-width:767px) {
        margin-bottom: 85px;
    }
    dt,
    dd {
        vertical-align: middle;
        display: inline-block;
        width: 7%;
        a {
            display: block;
            height: 35px;
            line-height: 30px;
            text-decoration: none;
            color: #A1A1A1;
            border-bottom: 2px solid rgba(255,255,255,0);
            border-radius: 0;
           
            @media (min-width:767px) {
                &:hover {
                    color: #DA0E2B;
                    border-color:#DA0E2B ;
                }
            }
        }
    }

    dd {
        width: 5.3%;
        @media (max-width:767px) {
            width: 15%;
        }
    }

    dd.active a {
        background: none;
        padding: 0;
        color: #DA0E2B;
        border-color:#DA0E2B ;
    }

    dt.rtbn a,
    dt.ltbn a {
        width: 32px;
        height: 32px;
        transition: 0.4s;
        position: relative;
        top: -1px;
        // background: url(../images/icon_arrow_mb.png) no-repeat center center / 6px;
        background: url(../images/arrow_r_mb.png) no-repeat center center / 8px;
        @media (max-width:767px) {
            top: -1px;
            background: url(../images/icon_arrow_mb.png) no-repeat center center / 9px;
        }
    }

    dt.nopage.ltbn a,dt.nopage.rtbn a{
        pointer-events: none;
        @media (min-width:767px) {
            &:hover{
                background: url(../images/arrow_r_mb.png) no-repeat center center / 8px;
            }
        }
        @media (max-width:767px) {
            background: url(../images/arrow_r_mb.png) no-repeat center center / 8px;
            // background: url(../images/icon_arrow_mb.png) no-repeat center center / 9px;
        }
        
    }

    dt.ltbn a {
        margin-right: $lr_btn;
        transform: rotate(180deg);
        &:before {
        }
        @media (min-width:767px) {
            &:hover {
                background: url(../images/icon_arrow_mb.png) no-repeat center center / 9px;
                border-color:rgba(255,255,255,0);
            }
        }
        
        @media (max-width:767px) {
            left: -10px;
        }
    }

    dt.rtbn a {
        margin-left: $lr_btn;

        &:before {
        }
        @media (min-width:767px) {
            &:hover {
                // background: #2f3a88;
                background: url(../images/icon_arrow_mb.png) no-repeat center center / 9px;
                border-color:rgba(255,255,255,0);
            }
        }

       
        @media (max-width:767px) {
            margin-left: 0;
            right: -7px;
        }
    }
}

//版本使用
@include screen(768px) {
    .mo_use {
        display: block;
    }

    .pc_use {
        display: none;
    }

    .top_btn {
        right: 20px;
    }

    right: 20px;
}


@media (max-width:767px) {

    .top_btn a:hover {}

    

    .back_btn,
    .more_btn {
        font-size: pxToEm(17);

        a {
            padding: 12.5px 25px;
            border-radius: 4px;
        }
    }

}