/// 需要推的padding-bottom以及預設圖用當前頁指定到此calss
.bgcover{
    width: 100%;
    height: 0;
    background: 50%/cover;
}
.mo{
    display:none;
    @media (max-width:767px) {
        display: block;
    }
}
.pc{
    display: none;
    @media (min-width:768px) {
        display: block;
    }
}
.container{
    padding: 0 15px;
    margin: 0 auto;
    width: 100%;
    @media (max-width:767px) {
        padding: 0 20px;
    }
    @media (min-width:576px) {
        max-width: 540px;
    }
    @media (min-width:768px) {
        max-width: 720px;
    }
    @media (min-width:992px) {
        max-width: 960px;
    }
    @media (min-width:1200px) {
        max-width: 1230px;
    }
}

// top-menu
#top-menu-ul{
    margin:0 auto 10px;
    position: relative;
    z-index: 1;
  
 
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 41px;
        border-bottom: 1px solid #E4E4E4;
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:left;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
        }

        li{
            height: 41px;
            &:nth-child(1) a{
                margin-left: 0;
            }
        }
        li a{
            position: relative;
            display: block;
            line-height: 1;
            letter-spacing: 0px;
            border-bottom: 3px solid rgba(255,255,255,0);
            font-size: pxToEm(18);
            color: #000000;
            transition: .2s;
            font-weight: 700;
            &::before{
                position: absolute;
                content: '';
                top: 50%;
                right: 0;
                width: 10px;
                height: 1px;
                background-color: #000000;
                transform: translateY(-50%);
                transition: .2s;
            }
            &::after{
                position: absolute;
                content: '';
                top: 50%;
                right: 0;
                width: 10px;
                height: 1px;
                background-color: #000000;
                transform: translateY(-50%) rotate(90deg);
                transition: .2s;
            }
                margin:0 20px; 
                padding: 10px 20px 10px 0;
                @media (max-width:767px) {
                    margin:0 15px; 
                }
          
        }
        li.active a{
            border-color:#DA0E2B;
            color:#DA0E2B;
            &::before{
                background-color: #DA0E2B;
            }
            &::after{
                opacity: 0;
            }
        }
        li a {
            @media (min-width:768px) {
                &:hover{
                    border-color:#DA0E2B;
                    color:#DA0E2B;
                    &::before{
                        background-color: #DA0E2B;
                    }
                    &::after{
                        transform: rotate(0deg);
                        // background-color: #DA0E2B;
                        opacity: 0;
                    }
                }
            }
        }
    }
}
.flex-direction-nav{
    display:none;
    a{
        display:block;
        width:40px;   
        height: 40px;
        position: absolute;        
        transform: translateY(-50%);
        top:50%;
        z-index: 10;
        background-color: #000000;
        &:after{
            position: absolute;
            top: 0;
        }
    }
    a.flex-next{
        background: url(../images/icon_arrow_mb.png) no-repeat center / 10px;
        right: -40px;
    }

    a.flex-prev{
        background: url(../images/icon_arrow_mb.png) no-repeat center / 10px;
        left:  -40px;
        transform: translateY(-50%) rotate(180deg);
    }
    }
    .open_flexslider{
    .flex-direction-nav{
        display: block;
        @media (max-width:767px) {
            display: none;
        }
    }
}


// top-menu
#top-menu-ul2{
   
        margin:0 auto 50px;
    
    position: relative;
    z-index: 1;
    .item_Menu{
        margin: 0 auto;   
        overflow: hidden;    
        height: 41px;
    }
    .item_menu_Box{    
        width:100%;
        overflow:hidden;
        overflow-x: scroll;
        position: relative;
        text-align:left;
        .item_menu_list{
            white-space: nowrap !important;
            padding-bottom: 0px;
            text-align: center;
            font-size:0px;
            display:inline-flex; 
        }

        li{
            height: 41px;
            &:nth-child(1) a{
                margin-left: 0;
            }
        }
        li a{
            position: relative;
            display: block;
            line-height: 1;
            letter-spacing: 0px;
            font-size: pxToEm(15);
            color: #000000;
            transition: .2s;
            
                margin:0 25px; 
                padding: 13px 20px 10px 0;
                @media (max-width:767px) {
                    margin:0 15px; 
                }
          
        }
        li.active a{
            color:#DA0E2B;
        }
        li a {
            @media (min-width:768px) {
                &:hover{
                    color:#DA0E2B;
                }
            }
        }
    }
}


.rh-btn{
    text-align: center;
    .link{
        padding-right: 25px;
        position: relative;
        transition: .4s;
        display: inline-block;
        span{
            @include fontset(14,2,0,500,#A1A1A1);
            position: relative;
            transition: .4s;
            display: block;
            @media (max-width:767px) {
                font-size: pxToEm(12);
                line-height: 1.5;
            }
        }
        &::before{
            position: absolute;
            content: '';
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 1px;
            background-color: #A1A1A1;
            transition: .4s;
        }
        @media (min-width:767px) {
            &:hover{
                span{
                    transform: translateX(-15px);
                    color: #DA0E2B;
                    font-weight: 700;
                }
                &::before{
                    width: 34px;
                    background-color: #DA0E2B;
                }
            }
        }
    }
    
}
.news-list{
    ul li{
        &:not(:last-child){
            margin-bottom: 18px;
        }
        // &:nth-child(1){
        // }
        // &:nth-child(2){
        //     transition-delay: .4s;
        // }
        // &:nth-child(3){
        //     transition-delay: .8s;
        // }
        // &:nth-child(4){
        //     transition-delay: 1.2s;
        // }
        // &:nth-child(5){
        //     transition-delay: 1.6s;
        // }
        // &:nth-child(6){
        //     transition-delay: 2s;
        // }
        // &:nth-child(7){
        //     transition-delay: 2.4s;
        // }
        // &:nth-child(8){
        //     transition-delay: 2.8s;
        // }
        // &:nth-child(9){
        //     transition-delay: 3.2s;
        // }
        // &:nth-child(10){
        //     transition-delay: 3.6s;
        // }
    }
    ul li a{
        @media (min-width:767px) {
            &:hover{
                .link{
                    span{
                        transform: translateX(-15px);
                        color: #DA0E2B;
                        font-weight: 700;
                    }
                    &::before{
                        width: 34px;
                        background-color: #DA0E2B;
                    }
                }
            }
        }
        .date{
            @include fontset(14,2,0,400,#DA0E2B);
            @media (max-width:767px) {
                font-size: pxToEm(12);
                line-height: 1.4;
            }
        }
        .des-bx{
            display: flex;
            justify-content: space-between;
            .l-bx{
                width: 57%;
                max-height: 55px;
                overflow: hidden;
                @include fontset(18,1.5,0,400);
                @media (max-width:767px) {
                    font-size: pxToEm(14);
                    max-height: 40px;
                    width: 64%;
                }
            }
            .r-bx{

            }
        }
    }
}
.sh-banner{
    width: 100vw;
    height: 480px;
    overflow: hidden;
    @media (max-width:767px) {
        padding-bottom: 62.5%;
        height: 0;
    }
    .bgcover{
        width: 100vw;
        height: 480px;
        background-size: cover !important;
        @media (max-width:767px) {
            padding-bottom: 62.5%;
            height: 0;
        }
    }
    h1{
        display: none;
    }
}
.sh-productlist{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    @media (max-width:767px) {
        justify-content: space-between;
    }
    li{
        width: 32%;
        margin-bottom: 50px;
        @media (min-width:768px) {
            &:not(:nth-child(3n)){
                margin-right: 2%;
            }
        }
        @media (max-width:767px) {
            width: 48%;
            &:last-child{
                margin-bottom: 65px;
            }
        }
        &:nth-child(odd){

        }
        &:nth-child(3n-1){
            transition-delay: .4s;
        }
        &:nth-child(3n){
            transition-delay: .8s;
        }
        @media (max-width:450px) {
            width: 100%;
            margin-bottom: 80px;
        }
        a{
            display: block;
            .img-bx{
                width: 100%;
                padding-bottom: 100%;
                position: relative;
                .unh{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    transition: .4s;
                    opacity: 1;
                }
                .h{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    transition: .4s;
                    opacity: 0;
                }
            }
            .des{
                @include fontset(16,1.5,0,300,#242424);
                transition: .4s;
            }
            .ti{
                @include fontset(18,1.5,0,700,#242424);
                transition: .4s;
            }
            @media (min-width:768px) {
                &:hover{
                    .des{
                        color: #DA0E2B;
                    }
                    .ti{
                        color: #DA0E2B;
                    }
                    .img-bx .unh{
                        opacity: 0;
                    }
                    .img-bx .h{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.sh-backbtn{
    display: inline-block;
    .back-bx{
        display: flex;
        align-items: center;
        .line{
            position: relative;
            width: 16px;
            transition: .4s;
            margin-right: 8px;
            &::before{
                position: absolute;
                content: '';
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #A1A1A1;
                transform: translateY(-50%);
                transition: .4s;
            }
        }
        .tx{
            @include fontset(14,2,0,400,#A1A1A1);
            transition: .4s;
            @media (max-width:767px) {
                font-size: pxToEm(12);
            }
        }
    }
    @media (min-width:768px) {
        &:hover{
            .back-bx .line{
                width: 30px;
                &::before{
                    background-color: #DA0E2B;
                }
            }
            .back-bx .tx{
                color: #DA0E2B;
            }
        }
    }
}

.s-nothing{
    @include fontset(18,1.5,0,400,#242424);
}

.in_fade{
    opacity: 0;
    transition: 1s cubic-bezier(0.5, 1, 0.89, 1);
    top: 8px;
    position: relative;
}
.in_fade.active{
    opacity: 1;
    top: 0;
}